import { Pinia, Store, getActivePinia } from 'pinia';

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}
export const $ResetPinia = () => {
  const pinia = getActivePinia() as ExtendedPinia;

  if (!pinia) {
    throw new Error('There is no stores');
  }

  pinia._s.forEach((store) => {
    if (store?.$reset) store.$reset();
  });
};

export const $GetAllStates = () => {
  const pinia = getActivePinia() as ExtendedPinia;

  if (!pinia) {
    throw new Error('There is no stores');
  }

  const stores: Record<string, any> = {}; /* eslint-disable-line */

  pinia._s.forEach((store, name) => {
    stores[name] = store.$state;
  });

  return stores;
};
