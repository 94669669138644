import { InstallationState, Step } from 'src/types/Installation';
function generateTemplate() {
  const template: Step[] = [
    {
      route: { name: 'StartSetup' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.start) return true;
        return false;
      },
    },
    {
      route: { name: 'RequiredPermissions' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.permissions) return true;
        return false;
      },
    },
    {
      route: { name: 'ConnectToWiFi' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.connectedToWiFi) return true;
        return false;
      },
    },
    {
      route: { name: 'HardwarePreference' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.hardware) return true;
        return false;
      },
    },
    {
      route: { name: 'ElectrificationPurpose' },
      isCompleted: function (state: InstallationState) {
        // If the organization is not certified to install for electrification we want to skip this step
        if (!state?.electrification_enabled) return true;

        // We should not show the Electrification Purpose screen when organization is undefined. It happen when the user is in Test Installer organisation but is installing this on behalf of myself as a homeowner.
        if (state?.organization === undefined) return true;

        if (state?.system || state?.electrificationPurpose) return true;
        return false;
      },
    },
    {
      route: { name: 'ConnectionPreference' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.connection) return true;
        if (state.hardware === 'edge') {
          return true;
        }
        return false;
      },
    },
    {
      route: { name: 'ConnectHub' },
      isCompleted: function (state: InstallationState) {
        // We only need to connect the hub for WiFi
        if (
          state?.system ||
          state?.connection === 'ethernet' ||
          state?.hubConnected
        )
          return true;
        return false;
      },
    },
    {
      route: { name: 'DiscoverHubs' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.createdSystem) return true;
        return false;
      },
    },
    {
      route: { name: 'SetupComplete' },
      isCompleted: function (state: InstallationState) {
        if (state?.system) return true;
        return false;
      },
    },
    {
      route: {
        name: 'ConfigurationChecklist',
      },
      isCompleted: function (state: InstallationState) {
        if (state?.system) {
          const systemHasName = state?.system.name.length > 0;
          let systemHasZipCode = true;
          let systemHasTimezone = true;
          // If we have the full system object (system/id then lets see if it fully configured)
          if (
            state.system.hasOwnProperty('zip_code') &&
            state.system.hasOwnProperty('time_zone')
          ) {
            systemHasZipCode = state?.system.zip_code.length > 0;
            systemHasTimezone = state?.system.time_zone.length > 0;
          }
          // Note systems on the cloud are not currently returning this info...

          const systemHasDevices = true;

          // // only check for devices if this installation is for the selected system
          // if (systemsStore.getSelectedSystemId === state?.system.id) {
          //   const foundSelectedSystemsDevice = state?.devices.some(
          //     (device: Device) =>
          //       device.system_id === systemsStore.getSelectedSystemId
          //   );
          //   if (foundSelectedSystemsDevice) {
          //     systemHasDevices = true;
          //   } else {
          //     // we are looking at this systems installation
          //     // the devices we have loaded in are not ours or don't exist.
          //     systemHasDevices = false;
          //   }
          // }

          let electrificationModeCompleted = true;
          if (
            state?.electrification_enabled &&
            state?.electrificationPurpose &&
            state?.electrificationPurpose === 'yes'
          ) {
            electrificationModeCompleted =
              //state?.electrificationWorksheet &&
              state?.breakerRating !== undefined &&
              state?.controlledDevices !== undefined &&
              ((state?.monitorAllPowerSources === 'yes' &&
                state?.monitorMainCT !== undefined &&
                state?.monitorAuxCT !== undefined) ||
                state?.monitorAllPowerSources === 'no') &&
              state?.amperageLimit !== undefined &&
              state?.panelGuardReviewed === 'yes';
          }
          return (
            systemHasName &&
            systemHasZipCode &&
            systemHasTimezone &&
            systemHasDevices &&
            electrificationModeCompleted
          );
        } else {
          return false;
        }
      },
    },
  ];

  return template;
}

export default { generateTemplate };
