import { RouteRecordRaw } from 'vue-router';
import { systemsRoutes } from './systems.routes';
import { generalSettingsRoute } from './settings.routes';

export const homeownerRoutes: RouteRecordRaw[] = [
  {
    name: 'HomeownerLayout',
    path: '/homeowner',
    component: () => import('layouts/HomeownerLayout.vue'),
    children: [...systemsRoutes, ...generalSettingsRoute],
  },
];
