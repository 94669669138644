import { FCM } from '@capacitor-community/fcm';
import {
  ActionPerformed,
  PermissionStatus,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import { useGadgetsStore } from 'stores/gadgets';
import {
  DEVICE_ID_KEY,
  DEVICE_TOKEN_KEY,
} from 'stores/gadgets/useGadgetsStore';

export function usePushNotifications() {
  // Check existing permissions, run setup if not granted
  const handleNotifications = async () => {
    try {
      const permissionStatus: PermissionStatus =
        await PushNotifications.checkPermissions();

      if (permissionStatus.receive !== 'granted') {
        await setupNotifications();
      } else {
        await checkForDeviceToken();
      }

      addListeners();
    } catch (error) {
      console.error('Error handling push notifications: ', error);
    }
  };

  const checkForDeviceToken = async () => {
    // Get the current device token from FCM
    const currentDeviceToken = await getFCMToken();

    // Get the stored device token from local storage
    const storedDeviceToken = localStorage.getItem(DEVICE_TOKEN_KEY);

    const gadgetsStore = useGadgetsStore();

    // If the two tokens are not the same, we should adopt
    // the current token and overwrite the stored token
    if (currentDeviceToken && storedDeviceToken !== currentDeviceToken) {
      await gadgetsStore.registerGadget(currentDeviceToken);
      localStorage.setItem(DEVICE_TOKEN_KEY, currentDeviceToken);
    }

    // If we don't have a deviceId in localStorage, generate one
    const storedDeviceId = localStorage.getItem(DEVICE_ID_KEY);
    if (!storedDeviceId) {
      const newDeviceId = gadgetsStore.generateGadgetId();

      localStorage.setItem(DEVICE_ID_KEY, newDeviceId);
    }
  };

  // Request permissions, register for push notifications
  const setupNotifications = async () => {
    const permissionStatus = await PushNotifications.requestPermissions();

    if (permissionStatus.receive === 'granted') {
      await PushNotifications.register();

      const deviceToken = await getFCMToken();
      if (deviceToken) {
        const gadgetsStore = useGadgetsStore();
        gadgetsStore.registerGadget(deviceToken);
      }
      return;
    }
  };

  // Subscribe to FCM topic
  const subscribeToFCMTopic = (topic: string) => {
    FCM.subscribeTo({ topic: topic })
      .then(() => {
        // TODO: Decide if we want to utilize topics
      })
      .catch((error) =>
        console.error(`Error subscribing to '${topic}' topic: `, error),
      );
  };

  // Unsubscribe from FCM topic
  const unsubscribeFromFCMTopic = (topic: string) => {
    FCM.unsubscribeFrom({ topic: topic })
      .then(() => {
        // TODO: Decide if we want to utilize topics
      })
      .catch((error) =>
        console.error(`Error unsubscribing from ${topic} topic: `, error),
      );
  };

  // Get FCM token instead of the APN one returned by Capacitor
  const getFCMToken = () => {
    return FCM.getToken()
      .then((response) => response.token)
      .catch((error) => console.error('Error getting FCM token: ', error));
  };

  // Delete the old FCM token and get a new one
  const refreshFCMToken = () => {
    FCM.refreshToken()
      .then((response) => {
        // TODO: Send the refreshed token (response.token) to the backend
        // to update token value associated with current user's device
      })
      .catch((error) => console.error('Error refreshing FCM token: ', error));
  };

  // Remove FCM instance
  const removeFCMToken = () => {
    FCM.deleteInstance()
      .then(() => {
        // TODO: Remove the token associated with current user's device
      })
      .catch((error) => console.error('Error removing FCM token: ', error));
  };

  // Enable FCM auto-initialization
  const setFCMAutoInit = () => {
    FCM.setAutoInit({ enabled: true })
      .then(() => {
        // Not being used currently
      })
      .catch((error) =>
        console.error('Error setting FCM auto-initialization: ', error),
      );
  };

  // Check FCM auto-initialization status
  const isFCMAutoInitEnabled = () => {
    FCM.isAutoInitEnabled()
      .then(() => {
        // Not being used currently
      })
      .catch((error) =>
        console.error(
          'Error checking FCM auto-initialization enabled: ',
          error,
        ),
      );
  };

  // Add listeners for PN Events
  const addListeners = async () => {
    // PN Registration
    await PushNotifications.addListener('registration', (token: Token) => {
      console.info('Registration token: ', token);
    });

    // PN Received
    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.info('Push Notification Received', notification);
      },
    );

    // PN Action Performed
    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (actionPerformed: ActionPerformed) => {
        console.info('Push Notification Action Performed', actionPerformed);
      },
    );
  };

  return {
    addListeners,
    getFCMToken,
    handleNotifications,
    isFCMAutoInitEnabled,
    refreshFCMToken,
    removeFCMToken,
    setFCMAutoInit,
    setupNotifications,
    subscribeToFCMTopic,
    unsubscribeFromFCMTopic,
  };
}
