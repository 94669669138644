import { computed, ref } from 'vue';
import { Banner } from 'types/Notification';
import { useSupportStore } from '../stores/useSupportStore';

const banners = ref<Banner[]>([]);

export function useAppBanners() {
  const getBanners = computed(() => banners.value);
  function createBanner(banner: Banner) {
    if (banner) {
      const shouldAddNewBanner = !banners.value.find(
        (b: Banner) => b.id === banner.id,
      );
      if (shouldAddNewBanner) {
        if (banner.url && banner.url.length) {
          banner.url = null;
        }
        banners.value.push(banner);
      }
    }
  }
  // Really just using thi to remove banners that the app creates, not from the api... we could tho.
  function removeBanner(id: string | number) {
    const supportStore = useSupportStore();
    const banner = banners.value.find((b) => b.id === id);
    if (banner)
      if (banner.isSupportStatus) {
        supportStore.saveViewedStatusId(id, 'banners');
      } else {
        banners.value = banners.value.filter((b) => b.id !== id);
      }
  }

  return { getBanners, createBanner, removeBanner };
}
