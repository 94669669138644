/* eslint-disable @typescript-eslint/no-explicit-any */
// Insert your tree and text to find
export function recursiveSearch(obj: object, key: string): any {
  if (key in obj) return obj[key as keyof typeof obj];
  for (const n of Object.values(obj)
    // Get rid of all null or undefined values
    .filter(Boolean)
    .filter((v) => typeof v === 'object')) {
    const found = recursiveSearch(n, key);
    if (found) return found;
  }
}
