<script lang="ts">
import { defineComponent } from 'vue';
import { useAnalyticsStore } from 'src/stores/useAnalyticsStore';
import LIcon from './LIcon/LIcon.vue';

export default defineComponent({
  props: {
    icon: { required: true, type: String },
    eventName: { type: String, default: 'button' },
    size: { type: String, default: '23px' },
  },
  components: { LIcon },
  setup(props) {
    const analyticsStore = useAnalyticsStore();

    return {
      onClick: async () => {
        analyticsStore.trackEvent(props.eventName, {});
      },
    };
  },
});
</script>

<template>
  <q-btn
    @click="onClick"
    dense
    round
    rounded
    unelevated
    no-caps
    class="q-pa-none"
  >
    <q-avatar :size="size">
      <LIcon class="circle-btn-icon" :size="size" :name="icon" />
    </q-avatar>
    <slot></slot>
  </q-btn>
</template>
