import { createApp } from 'vue';
import { Quasar, Notify, LocalStorage, LoadingBar } from 'quasar';
import BuildingBlocks from '@luminsmart/building-blocks';

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-icons-round/material-icons-round.css';
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css';
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css';
import '@quasar/extras/material-symbols-rounded/material-symbols-rounded.css';

// Import Quasar css
import 'quasar/src/css/index.sass';

// Import our css
import './css/dark.scss';
import './css/light.scss';
import './css/overrides.scss';
import './css/positioning.scss';
import './css/typography.scss';

import '@luminsmart/building-blocks/dist/building-blocks.css';
import './css/app.scss';

// Assumes your root component is App.vue
// and placed in same folder as main.js
import App from './App.vue';
import pinia from './stores';
import { setupAuthConfig } from './boot/auth';
import { setupAxios } from './boot/axios';
import { instance } from './boot/i18n';
import setupRouter from './router';
import { loadComponents } from './boot/iconSet.ts';
import { useEnvironments } from './composables/api/useEnvironments.ts';

// Sentry
import * as Sentry from '@sentry/vue';

const myApp = createApp(App);
myApp.use(setupRouter());

const { getEnvironment } = useEnvironments();

Sentry.init({
  app: myApp,
  dsn: getEnvironment.value.sentry.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  beforeSend(event) {
    // Anonymize or redact user-identifiable information
    if (event.user) {
      event.user.ip_address = undefined; // Remove IP address
    }
    return event;
  },
  // Performance Monitoring
  tracesSampleRate: 0.5, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api.luminsmart.io/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

myApp.use(Quasar, {
  plugins: { Notify, LocalStorage, LoadingBar }, // import Quasar plugins and add here
  config: {
    capacitor: {
      backButton: false,
      backButtonExit: ['/home'],
      iosStatusBarPadding: false,
    },
    loadingBar: {
      color: 'primary',
    },
  },
});

myApp.use(pinia);

setupAxios(myApp);
myApp.use(instance);

loadComponents(myApp);

const authConfig = setupAuthConfig();
// Setup auth and the base api url using building blocks
myApp.use(BuildingBlocks, {
  api: { baseUrl: getEnvironment.value.api.VITE_API_BASE_URL },
  auth: {
    auth0: authConfig,
  },
  theme: 'default',
});

// Assumes you have a <div id="app"></div> in your index.html
myApp.mount('#app');
