<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4133 13.2051H0.253333C0.113421 13.2051 0 13.3146 0 13.4498V13.7556C0 13.8908 0.113421 14.0003 0.253333 14.0003H12.4133C12.5532 14.0003 12.6667 13.8908 12.6667 13.7556V13.4498C12.6667 13.3146 12.5532 13.2051 12.4133 13.2051Z"
      fill="#C4C4C4"
    />
    <path
      d="M11.1467 0.541992H1.52006C1.38014 0.541992 1.26672 0.651547 1.26672 0.786689V1.09256C1.26672 1.2277 1.38014 1.33726 1.52006 1.33726H11.1467C11.2866 1.33726 11.4001 1.2277 11.4001 1.09256V0.786689C11.4001 0.651547 11.2866 0.541992 11.1467 0.541992Z"
      fill="#C4C4C4"
    />
    <path
      d="M8.16995 3.41699H4.49662C4.35671 3.41699 4.24329 3.52655 4.24329 3.66169V3.96756C4.24329 4.1027 4.35671 4.21226 4.49662 4.21226H8.16995C8.30987 4.21226 8.42329 4.1027 8.42329 3.96756V3.66169C8.42329 3.52655 8.30987 3.41699 8.16995 3.41699Z"
      fill="#C4C4C4"
    />
    <path
      d="M8.48666 6.78223H4.24332C4.10341 6.78223 3.98999 6.89178 3.98999 7.02692V7.3328C3.98999 7.46794 4.10341 7.57749 4.24332 7.57749H8.48666C8.62657 7.57749 8.73999 7.46794 8.73999 7.3328V7.02692C8.73999 6.89178 8.62657 6.78223 8.48666 6.78223Z"
      fill="#C4C4C4"
    />
    <path
      d="M9.24671 10.3301H3.48337C3.34346 10.3301 3.23004 10.4396 3.23004 10.5748V10.8806C3.23004 11.0158 3.34346 11.1253 3.48337 11.1253H9.24671C9.38662 11.1253 9.50004 11.0158 9.50004 10.8806V10.5748C9.50004 10.4396 9.38662 10.3301 9.24671 10.3301Z"
      fill="#C4C4C4"
    />
    <path
      d="M9.48077 12.8982L3.56573 10.4537C3.4371 10.4005 3.28821 10.4581 3.23317 10.5824L3.1086 10.8636C3.05356 10.9878 3.11323 11.1317 3.24186 11.1848L9.15689 13.6294C9.28552 13.6826 9.43442 13.6249 9.48945 13.5007L9.61402 13.2195C9.66906 13.0952 9.6094 12.9514 9.48077 12.8982Z"
      fill="#C4C4C4"
    />
    <path
      d="M9.48417 10.3389L4.02754 7.04663C3.90888 6.97503 3.75259 7.00991 3.67847 7.12453L3.5107 7.38395C3.43658 7.49856 3.47269 7.64952 3.59135 7.72112L9.04797 11.0134C9.16664 11.085 9.32292 11.0502 9.39705 10.9355L9.56481 10.6761C9.63894 10.5615 9.60283 10.4105 9.48417 10.3389Z"
      fill="#C4C4C4"
    />
    <path
      d="M9.05936 7.21913L4.6271 3.63748C4.51979 3.55077 4.36002 3.5645 4.27025 3.66815L4.06705 3.90275C3.97727 4.0064 3.99149 4.16072 4.0988 4.24744L8.53106 7.82909C8.63837 7.91581 8.79814 7.90208 8.88791 7.79843L9.09111 7.56383C9.18089 7.46017 9.16667 7.30585 9.05936 7.21913Z"
      fill="#C4C4C4"
    />
    <path
      d="M1.26672 1.15326V2.43792C1.26672 2.57306 1.38014 2.68262 1.52006 2.68262H1.83672C1.97664 2.68262 2.09006 2.57306 2.09006 2.43792V1.15326C2.09006 1.01812 1.97664 0.908562 1.83672 0.908562H1.52006C1.38014 0.908562 1.26672 1.01812 1.26672 1.15326Z"
      fill="#C4C4C4"
    />
    <path
      d="M10.5767 1.15326V2.43792C10.5767 2.57306 10.6901 2.68262 10.83 2.68262H11.1467C11.2866 2.68262 11.4 2.57306 11.4 2.43792V1.15326C11.4 1.01812 11.2866 0.908562 11.1467 0.908562H10.83C10.6901 0.908562 10.5767 1.01812 10.5767 1.15326Z"
      fill="#C4C4C4"
    />
    <path
      d="M4.43372 1.21668L2.23399 13.1663C2.20949 13.2994 2.30131 13.4264 2.43906 13.4501L2.75083 13.5036C2.88859 13.5273 3.02011 13.4386 3.0446 13.3055L5.24434 1.35591C5.26883 1.22285 5.17702 1.09581 5.03926 1.07215L4.72749 1.0186C4.58974 0.994944 4.45821 1.08363 4.43372 1.21668Z"
      fill="#C4C4C4"
    />
    <path
      d="M10.4179 13.153L8.24234 1.33174C8.21785 1.19868 8.08633 1.10999 7.94858 1.13365L7.6368 1.18718C7.49905 1.21084 7.40723 1.33787 7.43171 1.47093L9.6073 13.2922C9.63179 13.4252 9.76331 13.5139 9.90106 13.4903L10.2128 13.4367C10.3506 13.4131 10.4424 13.286 10.4179 13.153Z"
      fill="#C4C4C4"
    />
    <path
      d="M3.10333 3.2334H0.253333C0.113421 3.2334 0 3.34295 0 3.4781V3.78397C0 3.91911 0.113421 4.02866 0.253333 4.02866H3.10333C3.24324 4.02866 3.35667 3.91911 3.35667 3.78397V3.4781C3.35667 3.34295 3.24324 3.2334 3.10333 3.2334Z"
      fill="#C4C4C4"
    />
  </svg>
</template>
