import { LocalStorage } from 'quasar';
import { useSystemsStore } from 'src/stores/systems';
import { createRouter, createWebHistory } from 'vue-router';
import { useAppLoadingStatus } from '../composables/useAppLoadingStatus';
import routes from './routes';
import { useAuth } from 'src/composables/useAuth';
import { until } from '@vueuse/core';
import { useOrganizations } from '@luminsmart/building-blocks';
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

// List of routes to redirect with system Id in

export default function setupRouter() {
  const createHistory = createWebHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(import.meta.env.VITE_VUE_ROUTER_BASE),
  });

  Router.beforeEach(async (to) => {
    // Start - Auth handling
    const auth = useAuth();
    if (auth?.isLoading?.value !== false) {
      useAppLoadingStatus().setLoading(true);
      await until(auth.isLoading).toBe(false, { timeout: 5000 });
      useAppLoadingStatus().setLoading(false);
    }
    const systemsStore = useSystemsStore();
    const organizationStore = useOrganizations();
    //  If they get logged out while in the app and request a page that requires auth prompt for user to log in
    if (
      (to.meta.requiresAuth || to.name === 'Root') &&
      !auth.isAuthenticated?.value
    ) {
      return {
        name: 'AuthPage',
      };
    }

    // if they land on the auth page and they are logged in then lets send them home or to the setup flow or installer?
    if (
      (to.name === 'AuthPage' || to.name === 'Root') &&
      auth.isAuthenticated?.value
    ) {
      useAppLoadingStatus().setLoading(true);
      // If there isn't a selected system id and there are no systems loaded lets load the systems
      if (
        !systemsStore.getSelectedSystemId.length &&
        (systemsStore.systems.length === 0 ||
          organizationStore.systems.value.length === 0)
      ) {
        await systemsStore.fetchSystems();
      }
      useAppLoadingStatus().setLoading(false);
      if (!systemsStore.getSelectedSystemId.length) {
        return {
          name: 'StartSetup',
        };
      } else {
        return {
          name: 'Home',
          params: { system_id: systemsStore.getSelectedSystemId },
        };
      }
    }
    // End - Auth handling

    // If we left the app and saved a return-to-path we want to return to that page when we go back into the app
    const returnToRoute = LocalStorage.getItem('return-to-route') as string;
    // TODO add logic to prompt user to return to a route
    // const promptToReturnToRoute = LocalStorage.getItem(
    //   'prompt-to-return-to-route'
    // );
    // If the app requires to you to go back to a page
    if (returnToRoute) {
      LocalStorage.remove('return-to-route');
      return returnToRoute;
    }

    return true;
  });
  return Router;
}
