import { computed, ref } from 'vue';

const showLoadingScreen = ref(false);
export function useAppLoadingStatus() {
  const isAppLoading = computed(() => {
    return showLoadingScreen.value;
  });

  function setLoading(loading: boolean) {
    showLoadingScreen.value = loading;
  }
  return { isAppLoading, setLoading };
}
