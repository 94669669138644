import { Platform } from 'quasar';
import { useEnvironments } from 'src/composables/api/useEnvironments';

export function setupAuthConfig() {
  const { getEnvironment } = useEnvironments();
  const environment = getEnvironment.value;
  let redirectUri = window.location.origin;
  if (Platform.is.capacitor) {
    if (Platform.is.android) {
      redirectUri = environment.auth0.VITE_ANDROID_CALLBACK_URL;
    } else if (Platform.is.ios) {
      redirectUri = environment.auth0.VITE_IOS_CALLBACK_URL;
    }
  }

  return {
    domain: environment.auth0.VITE_AUTH_DOMAIN,
    clientId: environment.auth0.VITE_AUTH_CLIENT_ID,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    useRefreshTokensFallback: false,
    authorizationParams: {
      redirect_uri: redirectUri,
      scope: 'openid profile email offline_access',
      // Audience is required in order to refresh the token https://community.auth0.com/t/how-to-troubleshoot-access-denied-due-to-service-not-found-error/6822
      audience: environment.auth0.VITE_AUDIENCE,
    },
  };
}
