import { Notify } from 'quasar';

import { i18n } from 'boot/i18n';
import { useDevicesStore } from 'stores/devices';
import { Device } from 'types/Device.ts';
import { ApplianceStatusEvent, ControlEventData, Event } from 'types/Event';

export default function handleEvent(event: Event) {
  const devicesStore = useDevicesStore();
  const { t } = i18n;
  if (!event?.data) return;
  let eventData;
  let device;
  switch (event?.data.type) {
    case 'device_control_executed':
      eventData = event?.data.data as ControlEventData;
      device = devicesStore.getDeviceById(eventData.device_id);

      const switchOpen = eventData.attributes.switch_open;
      if (!device) {
        throw new Error(
          'Received device_control_executed event, but the device was not found',
        );
      }
      // Devices when they are updated event the power on state. this check is to only alert when there is a change in that state.
      if (
        device.attributes.switch_open.value !== eventData.attributes.switch_open
      ) {
        Notify.create({
          group: false,
          message: `${device?.name} ${switchOpen ? t('circuit-disabled') : t('circuit-enabled')}`,
        });

        devicesStore.updateDeviceAttributes(
          {
            switch_open: {
              value: switchOpen,
              type: 'controllable',
            },
          },
          eventData.device_id,
        );
      }
      break;
    case 'device_metadata_updated':
      // {"type":"events","data":{"id":"2dNcL1Rc5RrXClp6dNEx0wfwTsF","type":"device_metadata_updated","version":1,"data":{"device_id":"2cgmbvuOociIZ5bb5H506zkmX9n","system_id":"2cgmYTkhqOq5BzbsRd8vhrvnfji"},"timestamp":"2024-03-07 22:51:56.26807 +0000 UTC"}}
      eventData = event?.data.data as ControlEventData;
      device = devicesStore.getDeviceById(eventData.device_id);
      if (!device) {
        throw new Error(
          'Received device_metadata_updated event, but the device was not found',
        );
      }
      Notify.create({
        group: false,
        message: `${device.name} updated`,
      });
      devicesStore.fetchDevice(eventData.device_id);
      //
      break;
    case 'fleet_control_event_created':
      Notify.create({
        group: false,
        message: t('fleet_control_event_created'),
        position: 'top',
      });
      break;
    case 'automation_created':
      //{"type":"events","data":{"id":"2dMmH1v5jANYSSdvdp92z2qAb2y","type":"automation_created","version":1,"data":{"automation_id":"2dMlctCCVRWBIRMeDtRlsU7eNxx","system_id":"2cgcORKifGlArTGw2aWaxXqCohS"},"timestamp":"2024-03-07 15:38:30.538824 +0000 UTC"}}
      // Notify.create({
      //   group: false,
      //   message: t('automation_created'),
      //   position: 'top',
      // });
      break;
    case 'appliance_status':
      eventData = event?.data as ApplianceStatusEvent;
      const items = eventData.data.appliance_status;

      for (const item of items) {
        const deviceId = item.device_id;
        const inUseStatus = item.in_use_status;
        const typicalUseAmps = item.typical_use_amps;
        const typicalUseWatts = item.typical_use_watts;

        if (deviceId) {
          const device: Device | undefined = devicesStore.getDeviceById(
            item.device_id,
          );

          const prevState: boolean | undefined = device?.attributes?.in_use_status?.value

          if (prevState === undefined || inUseStatus != prevState) {
            Notify.create({
              group: false,
              message: `${device?.name} ${!inUseStatus ? t('turned-off-lowercase') : t('turned-on-lowercase')}`,
            });
          }

        }

        devicesStore.updateDeviceAttributes(
          {
            in_use_status: {
              value: inUseStatus,
              type: 'observable',
            },
            typical_use_amps: {
              value: typicalUseAmps,
              type: 'observable',
              unit_of_measurement: 'A',
            },
            typical_use_watts: {
              value: typicalUseWatts,
              type: 'observable',
              unit_of_measurement: 'W',
            },
          },
          deviceId,
        );
      }

      break;
    case 'overload_decision_changed':
      break;
    default:
      console.warn('Unhandled ws Event', event);
      break;
  }
}
