<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import LCircleButton from 'components/elements/LCircleButton.vue';

export default defineComponent({
  name: 'BackButton',
  props: { shouldGoBack: { default: true } },
  components: {
    LCircleButton,
  },
  setup(props) {
    const router = useRouter();
    const { t } = useI18n();

    function goBack() {
      if (props.shouldGoBack) router.back();
    }

    return {
      t,
      goBack,
    };
  },
});
</script>
<template>
  <LCircleButton icon="close" @click="goBack()" eventName="close-button" />
</template>
