import { computed, ref, watch } from 'vue';
import { useSystemsStore } from 'src/stores/systems';
import { useAppBanners } from 'src/composables/useAppBanners';
import { useNetwork } from 'src/composables/useNetwork';
import { createSharedComposable } from '@vueuse/core';
import { useSocketStore } from 'src/stores/useSocketStore';
import { useZeroConf, ZeroConfType } from './useZeroConf';
import { i18n } from 'src/boot/i18n';
import { LoadingBar } from 'quasar';
const appBanners = useAppBanners();
const { isWiFi } = useNetwork();
const MAX_LOGS_TO_SAVE = 100;
export interface LogTrace {
  status: number;
  createdAt: number;
  reason: Record<string, any> /* eslint-disable-line */;
  systemId: string;
}

export interface SystemHeathState {
  id: 'local' | 'cloud' | 'socket' | 'api';
  status: number;
  logs: LogTrace[];
}

// This represents the status of the systems health
const states = ref<Record<string, SystemHeathState>>({
  socket: { id: 'socket', status: 2, logs: [] },
  api: { id: 'api', status: 2, logs: [] },
});

export const STATUS_MAP = {
  ONLINE: 1,
  OFFLINE: 0,
  ONLINE_WITH_ISSUES: -1,
  LOADING: 2,
};

// watch for for the connection status to change via the isWiFi flag and if it changes clear the health check
watch(isWiFi, async () => {
  resetState();
});

export const useHealthCheck = createSharedComposable(healthCheck);

function healthCheck() {
  const systemsStore = useSystemsStore();
  const zeroConf = useZeroConf();
  const socketStore = useSocketStore();
  const getConnectionStatus = computed(() => {
    const { t } = i18n;
    let info = t('loading');
    let status = STATUS_MAP.LOADING;
    let iconName = 'loading';

    const { socket, api } = states.value;
    // If local API is available or cloud API is available
    const isApiAvaiable = api.status === STATUS_MAP.ONLINE;
    const isWSAvailable = socket.status === STATUS_MAP.ONLINE;
    const isLuminsCloudApi = checkIfCloud(api.logs);
    const isLocal = checkIfLocal(
      api.logs as LogTrace[],
      systemsStore.getSelectedSystemId,
      zeroConf,
    );

    if (api.status === STATUS_MAP.LOADING) {
      info = `${t('loading')}`;
      status = STATUS_MAP.LOADING;
      iconName = 'loading';
    } else if (isApiAvaiable) {
      // figure out if we are local or cloud or other
      if (isLocal) {
        info = `${t('online')} -  ${t('local')}`;
        status = STATUS_MAP.ONLINE;
        iconName = 'local.vue';
      } else if (isLuminsCloudApi) {
        info = `${t('online')} -  ${t('cloud')}`;
        status = STATUS_MAP.ONLINE;
        iconName = 'cloud.vue';
      } else {
        // The app is talking to another API
        info = `${t('online')} -  ${t('other')}`;
        status = STATUS_MAP.ONLINE;
        iconName = 'cloud.vue';
      }

      // we want to handle the WS connnection status slight dif then hte reg API
      if (socket.status === STATUS_MAP.LOADING) {
        info = info + ' - connecting';
        appBanners.removeBanner('SOCKET_UNAVAILABLE');
      } else if (!isWSAvailable && systemsStore.getSelectedSystemId) {
        // If the loading bar is active then the app is trying to reestablish the data and we don't want ot warn the user about issues yet.
        if (LoadingBar.isActive) {
        } else {
          info = info + ' - Live data unavailable';
          status = STATUS_MAP.ONLINE_WITH_ISSUES;
          iconName = 'issues.vue';

          appBanners.createBanner({
            id: 'SOCKET_UNAVAILABLE',
            platforms: [],
            version: null,
            title: 'Live data disconnected',
            description: '',
            clickbtnText: 'Reconnect',
            classes: '',
            url: null,
            closable: false,
            callout: false,
            click: async () => {
              await socketStore.connectToSocket(
                systemsStore.getSelectedSystemId,
              );
              states.value.socket = { id: 'socket', status: 2, logs: [] };
              appBanners.removeBanner('SOCKET_UNAVAILABLE');
            },
          });
        }
      } else {
        appBanners.removeBanner('SOCKET_UNAVAILABLE');
      }
    } else {
      info = `${t('offline')}`;
      status = STATUS_MAP.OFFLINE;
      iconName = 'offline.vue';
    }

    return {
      status,
      info,
      iconName,
    };
  });

  return {
    states,
    getConnectionStatus,
    resetState,
  };
}

function resetState() {
  states.value = {
    socket: { id: 'socket', status: 2, logs: [] },
    api: { id: 'api', status: 2, logs: [] },
  };
}

export function updateSystemStatus(
  id: 'local' | 'cloud' | 'socket' | 'api',
  status: number,
  reason: object,
) {
  const systemsStore = useSystemsStore();
  const systemId = systemsStore.getSelectedSystemId;

  const log = { status, reason, systemId, createdAt: Date.now() };

  const logUrl = getLogsUrl(log);
  if (logUrl) {
    const sysIdFromUrl = getSystemIdFromUrl(logUrl);
    if (sysIdFromUrl) log.systemId = sysIdFromUrl;
  }

  states.value[id].logs.unshift(log);
  let logs = states.value[id].logs;
  //NOTE: socket statuses are really noisey, but still really useful, we are going to only look at the last 3 logs
  if (id === 'socket') {
    logs = states.value[id].logs.slice(0, 3);
  }
  states.value[id].status = calculateCompositeStatus(logs);

  // Make sure our logs array doesn't get too big
  if (states.value[id].logs.length > MAX_LOGS_TO_SAVE) {
    states.value[id].logs.pop();
  }
}
const testForErrors = (status: number) => {
  const errorStates = [STATUS_MAP.OFFLINE, STATUS_MAP.ONLINE_WITH_ISSUES];
  return errorStates.includes(status);
};
// generate a composite status for any array of logs
function calculateCompositeStatus(logs: LogTrace[]): number {
  const systemsLogs = filterToSystemsLogs(logs);
  const recentLogs = removeOldLogs(systemsLogs);
  // To make the composite status update fater we are only going to look at the last 3 logs. This way we can store more logs in memory to help with debugging
  // const atMost3Logs = recentLogs; //.slice(0, 3);
  const allRecentLogsAreErroring = recentLogs
    .map((state) => state.status)
    .every((status) => testForErrors(status));
  // TODO: add logic to only test if the latest log is in an error state maybe?
  const someLogsAreErroring = recentLogs
    .map((state) => state.status)
    .some((status) => testForErrors(status));
  const someLogsAreLoading = recentLogs
    .map((state) => state.status)
    .some((status) => status === STATUS_MAP.LOADING);

  if (someLogsAreLoading) {
    return STATUS_MAP.LOADING;
  } else if (allRecentLogsAreErroring) {
    return STATUS_MAP.OFFLINE;
  } else if (someLogsAreErroring) {
    return STATUS_MAP.ONLINE_WITH_ISSUES;
  } else {
    return STATUS_MAP.ONLINE;
  }
}

function filterToSystemsLogs(logs: LogTrace[]) {
  const systemsStore = useSystemsStore();
  const systemsLogs = logs.filter((log) => {
    const logUrl = getLogsUrl(log);
    if (
      (logUrl && logUrl.includes(systemsStore.getSelectedSystemId)) ||
      log.systemId === systemsStore.getSelectedSystemId
    ) {
      return log;
    }
  });
  return systemsLogs;
}

function removeOldLogs(logs: LogTrace[]) {
  const now = Date.now();
  const oneMinute = 1 * 60 * 1000;
  // const tenSeconds = 10 * 1000;
  const recentLogs = logs.filter((log) => {
    if (now - log.createdAt < oneMinute) {
      return log;
    }
  });
  return recentLogs;
}

function checkIfCloud(logs: LogTrace[]) {
  return logs.some((log) => log?.reason?.instance === 'cloud');
}

function checkIfLocal(
  logs: LogTrace[],
  systemId: string,
  zeroConf: ZeroConfType,
) {
  const systemsHubService = zeroConf.getServiceBySystemId(systemId);
  const hubId = systemsHubService?.txtRecord?.hub_id;
  return logs.some((log) => {
    return log?.reason?.instance === hubId;
  });
}

export function getLogsUrl(log: LogTrace) {
  try {
    const { response, error } = log?.reason?.config;
    const { method, baseURL, url } = response
      ? response?.config
      : error?.config;
    if (method && baseURL && url)
      return `${method.toUpperCase()}: ${baseURL}${url}`;
  } catch (e) {}
}

function getSystemIdFromUrl(url: string) {
  const match = url.match(/\/systems\/([^/]+)\//);
  if (match) {
    return match[1];
  }
  return null;
}
