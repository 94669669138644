import axios, { AxiosError } from 'axios';
import { Notify } from 'quasar';
import { captureException } from '@sentry/vue';

type Error = {
  message: string;
};
type Errors = {
  errors: Error[];
};

export function handleRequestErrors(e: unknown) {
  captureException(e);
  if (axios.isAxiosError(e)) {
    return e;
  }
}

export function notifyResponseErrors(error: AxiosError<unknown, unknown>) {
  if (error && error.response) {
    const data = error.response.data as Errors;
    const errors = data?.errors ? data.errors : (data as unknown as Error[]);
    if (errors)
      if (Array.isArray(errors)) {
        errors.forEach((errorInfo) => {
          if ((errorInfo as Error).message) Notify.create(errorInfo.message);
        });
      } else {
        const message = (errors as Error).message;
        if (message) Notify.create(message);
      }
  }
}
