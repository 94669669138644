<script setup lang="ts">
import { itemElement } from '@luminsmart/building-blocks';
import { useLocalAuth } from 'src/composables/useLocalAuth';
import { useZeroConf } from 'src/composables/useZeroConf';
import { useHubStore } from 'src/stores/useHubStore';
import { computed } from 'vue';
const zeroConf = useZeroConf();
const localAuth = useLocalAuth();
const hubStore = useHubStore();
const localInfo = computed(() => {
  return zeroConf.servicesArray.value.map((service) => {
    const hubId = service.txtRecord.hub_id;
    const localAuthToken = localAuth.localAuthTokens.value[hubId];
    return {
      service: service,
      token: localAuthToken,
      info: hubStore.allHubsInfo?.[hubId],
    };
  });
});
</script>
<template>
  <itemElement
    v-for="data in localInfo"
    :label="data.service.name"
    :key="data.service.hostname"
  >
    <pre lang="json" class="text-caption">
    {{ data }}
    </pre>
  </itemElement>

  <!-- <buttonElement
    v-for="service in services"
    :key="service.txtRecord.hub_id"
    :label="`Generate Token For ${service.name}`"
    @click="generateAuthToken(service)"
    class="btn-primary full-width"
  /> -->
</template>
