<template>
  <svg
    width="15"
    height="20"
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_18483_510427)">
      <path
        d="M5.19263 2.73411V1.50563C5.19263 0.864384 5.75881 0.337891 6.44631 0.337891C7.1419 0.337891 7.6919 0.864384 7.68382 1.50563V8.15429"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.6853 4.99402V2.55055C2.6853 1.90931 3.25148 1.38281 3.93899 1.38281C4.63458 1.38281 5.19267 1.90931 5.19267 2.55055V8.15298"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.37784 17.3943C2.37784 14.0666 0.404297 13.8978 0.404297 9.19988V4.71794C0.404297 4.1307 0.913858 3.6582 1.54474 3.6582C2.17563 3.6582 2.68519 4.1307 2.68519 4.71794V8.1469"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.68384 3.07789C7.68384 2.43665 8.25811 1.91016 8.9537 1.91016C9.64929 1.91016 10.1993 2.43665 10.1912 3.07789V9.59831"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.3147 11.3122C8.47646 10.5359 9.16397 10.1107 9.75441 9.88794C10.0618 9.77319 10.2882 9.55719 10.4096 9.30745C10.5794 8.9497 10.8382 8.42321 11.089 7.92371C11.9302 6.24298 13.1353 5.37224 13.8633 5.64224C14.8662 6.01349 14.0736 7.45122 13.4831 8.8282C12.8118 10.3874 12.8927 11.2717 10.8706 13.8366C9.9 15.0651 9.09926 15.9021 9.09926 17.3871"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.94111 19.6634H9.34188C9.60879 19.6634 9.82718 19.4946 9.82718 19.2786V17.7734C9.82718 17.5642 9.60879 17.3887 9.34188 17.3887H1.94111C1.67419 17.3887 1.45581 17.5574 1.45581 17.7734V19.2786C1.45581 19.4879 1.67419 19.6634 1.94111 19.6634Z"
        stroke="var(--q-primary)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_18483_510427">
        <rect width="14.7368" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
