import { RouteRecordRaw } from 'vue-router';
import DeveloperSettingsPage from 'src/pages/settings/developer';

export const unauthenticatedRoutes: RouteRecordRaw[] = [
  {
    name: 'UnauthenticatedLayout',
    path: '/authenticate',
    component: () => import('layouts/UnauthenticatedLayout.vue'),
    redirect: () => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { name: 'AuthPage' };
    },
    children: [
      {
        name: 'AuthPage',
        path: '/authenticate',
        component: () => import('pages/AuthPage.vue'),
        meta: {
          requiresAuth: false,
          showBackBtn: false,
          navBgTransparent: true,
        },
      },
      {
        name: 'AppSettings',
        path: '/settings/app',
        component: () => import('pages/settings/AppSettings.vue'),
        meta: {
          requiresAuth: false,
          showBackBtn: true,
        },
      },
      {
        name: 'DeveloperSettings',
        path: '/settings/developer',
        component: DeveloperSettingsPage,
        meta: {
          requiresAuth: false,
          showBackBtn: true,
        },
      },
      {
        name: 'GetApp',
        path: '/get-app',
        component: import('src/pages/GetApp'),
        meta: {
          requiresAuth: false,
          showBackBtn: false,
          hideNavBar: true,
        },
      },
    ],
  },
];
