<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.64592 22.9568C6.91297 23.0797 7.21666 22.93 7.31826 22.6378C7.41987 22.3456 7.28085 22.0218 7.01438 21.8974C5.27675 21.0862 3.78827 19.7558 2.72974 18.0595C1.57342 16.2065 0.988579 14.0105 1.05779 11.7815C1.12701 9.55254 1.84677 7.40318 3.11548 5.63691C4.27689 4.02002 5.84459 2.79893 7.62868 2.11378C7.90228 2.00871 8.06108 1.69548 7.97783 1.39668C7.89458 1.09788 7.60085 0.926786 7.32677 1.03036C5.33159 1.78431 3.57814 3.14303 2.2821 4.94734C0.878784 6.90101 0.0826464 9.27842 0.00608964 11.7439C-0.0704671 14.2094 0.576424 16.6384 1.85543 18.688C3.03666 20.581 4.70194 22.062 6.64592 22.9568Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2307 1.04072C16.9677 0.922941 16.6702 1.0706 16.572 1.35582C16.4737 1.64105 16.612 1.95571 16.8745 2.07484C18.6407 2.87618 20.1571 4.20259 21.2388 5.90027C22.4174 7.75001 23.0204 9.94706 22.9627 12.1812C22.905 14.4154 22.1894 16.5739 20.9171 18.3519C19.7493 19.9837 18.1667 21.2194 16.3619 21.9162C16.0936 22.0198 15.9393 22.3259 16.0226 22.6164C16.106 22.907 16.3954 23.0718 16.6641 22.9696C18.676 22.2045 20.4403 20.8337 21.7393 19.0185C23.1428 17.0571 23.9321 14.6761 23.9958 12.2115C24.0595 9.74689 23.3943 7.32327 22.0942 5.28278C20.8909 3.39431 19.2 1.92231 17.2307 1.04072Z"
      fill="currentColor"
    />
    <path
      d="M16.938 10.823C17.028 10.6413 17.0195 10.4417 16.9156 10.268C16.8117 10.0942 16.6271 10.025 16.4283 10.025H12.082L13.0176 1.68167C13.0495 1.39881 12.8785 1.10577 12.6123 1.0247C12.5573 1.00799 12.5017 1 12.4467 1C12.2356 1 12.0361 1.12321 11.9378 1.32559L6.05989 13.44C5.97171 13.6217 5.98142 13.8636 6.08562 14.0363C6.18988 14.2089 6.37368 14.3495 6.5717 14.3495H10.8608L9.98149 22.3788C9.95076 22.6606 10.1209 22.8975 10.3858 22.9787C10.4401 22.9955 10.4952 23 10.5496 23C10.7603 23 10.9598 22.88 11.0593 22.6791L16.938 10.823Z"
      fill="var(--q-primary)"
    />
  </svg>
</template>
