import { RouteRecordRaw } from 'vue-router';

const requiresAuth = true;

export const devicesRoutes: RouteRecordRaw[] = [
  {
    name: 'Devices',
    path: 'systems/:system_id/devices',
    component: () => import('src/pages/devices/ViewDevices'),
    meta: {
      requiresAuth,
    },
  },
  {
    name: 'AddDevices',
    path: 'systems/:system_id/devices/add',
    component: () => import('src/pages/devices/AddDevices'),
    meta: {
      requiresAuth,
      showBackBtn: true,
    },
  },
  {
    name: 'EditDevice',
    path: 'systems/:system_id/devices/:device_id/edit',
    component: () => import('src/pages/devices/EditDevice'),
    meta: {
      requiresAuth,
      showBackBtn: true,
    },
  },
  {
    name: 'ManageDevices',
    path: 'settings/systems/:system_id/manage-devices',
    component: () => import('src/pages/devices/AddDevices'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
];
