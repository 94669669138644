<script setup lang="ts">
import { watch, ref, computed } from 'vue';
import {
  useHealthCheck,
  SystemHeathState,
  getLogsUrl,
} from 'src/composables/useHealthCheck';

import LItem from 'components/elements/LItem.vue';
import LDialog from '../elements/LDialog.vue';

const { states } = useHealthCheck();
const selectedState = ref<SystemHeathState | null>(null);
const showDialog = ref(false);
const statusArray = computed(() => Object.values(states.value));

watch(selectedState, () => {
  showDialog.value = selectedState.value !== null;
});
function toggleState(status: SystemHeathState | null) {
  selectedState.value = status;
}

function prettyPrintStatus(status: number) {
  if (status === 1) {
    return 'Connected';
  } else if (status === -1) {
    return 'Connected - with issues';
  } else if (status === 2) {
    return 'Loading';
  }
  return 'Unreachable';
}

function getReadableTime(unixTimeStamp: number) {
  const date = new Date(unixTimeStamp).toLocaleDateString('en-US');
  const time = new Date(unixTimeStamp).toLocaleTimeString('en-US');
  return date + ' - ' + time;
}
</script>

<template>
  <div
    data-testid="health-check-statuses"
    id="health-check-statuses"
    class="q-gutter-y-sm"
  >
    <div class="text-boldl-pb-sm text-subtitle">App Health</div>
    <LItem
      v-for="state in statusArray"
      :key="state.id"
      :label="state.id"
      @click="toggleState(state)"
    >
      <template v-slot:caption>
        {{ prettyPrintStatus(state.status) }} - {{ state.logs.length }} logs
      </template>

      <div></div>
    </LItem>
    <LDialog
      v-model:show="showDialog"
      :maximized="true"
      @hide="toggleState(null)"
    >
      <template v-slot:header-text>
        <div v-if="selectedState">
          {{ selectedState.id }}
          <div class="l-pb-sm text-subtitle">
            <b>Composite Status:</b>
            {{ prettyPrintStatus(selectedState.status) }}
          </div>
        </div>
      </template>
      <template v-slot:body>
        <q-virtual-scroll
          separator
          class=""
          v-if="selectedState"
          style="height: 90vh"
          :items="selectedState.logs"
          v-slot="{ item }"
        >
          <LItem class="q-mt-sm" :noSideSlot="true" :clickable="false">
            <div>
              <b>Status:</b>

              <q-chip
                dense
                :color="
                  item.status === 1
                    ? 'positive'
                    : item.status === 2
                      ? 'positive'
                      : 'negative'
                "
                >{{ prettyPrintStatus(item.status) }}</q-chip
              >
            </div>
            <div><b>created At:</b> {{ getReadableTime(item.createdAt) }}</div>
            <div><b>System Id:</b> {{ item.systemId }}</div>
            <div>
              <b>Instance Id:</b>
              {{ item?.reason?.instance }}
            </div>
            <div v-if="item?.reason?.requestConfig?.retries">
              <b>Retries:</b>
              {{ item?.reason?.requestConfig?.retries }}
            </div>
            <div v-if="item?.reason?.config">
              <b>Request URL:</b>
              {{ getLogsUrl(item) }}
            </div>
            <q-expansion-item
              expand-separator
              icon="bug_report"
              label="Details"
            >
              <div class="text-small log-status">
                <pre>{{ JSON.stringify(item.reason, null, 1) }}</pre>
              </div>
            </q-expansion-item>
          </LItem>
          <LItem
            label="No logs captured yet."
            v-if="!selectedState.logs.length"
            :noSideSlot="true"
          />
        </q-virtual-scroll>
      </template>
    </LDialog>
  </div>
</template>
<style scoped>
.log-status {
  width: 100%;
}
</style>
