// =============Global Open URL Function=============
import { Platform } from 'quasar';
import { Browser } from '@capacitor/browser';

export function useBrowser() {
  function openUrl(url: string, target = '_blank') {
    if (Platform.is.capacitor) {
      Browser.open({ url: url });
    } else {
      window.open(url, target);
    }
  }

  return { openUrl };
}
