import { UseWebSocketReturn, useWebSocket } from '@vueuse/core';
import { useSystemsStore } from 'src/stores/systems';
import { useHubStore } from 'src/stores/useHubStore';
import { ComponentInternalInstance, computed, ref } from 'vue';
import { updateSystemStatus, STATUS_MAP } from '../useHealthCheck';
import { useEnvironments } from 'src/composables/api/useEnvironments';
export function useSocket() {
  const url = ref<string>();
  const stream = ref<UseWebSocketReturn<object>>();
  const connected = computed(() => {
    if (!stream?.value?.status) return false;
    // TODO: Theres something wrong with this typing, look into it later, this should work for now...
    return (stream.value.status as unknown as string) !== 'CLOSED';
  });
  function connect() {
    stream.value = useWebSocket(url, {
      immediate: false,
      /*
      These events are just used to keep track of the sockets status.
      you can interact with the return data from useSocket instead
      */
      onConnected: () => {
        const reason = {
          message: '[Sockets] Live readings connected.',
          instance: 'cloud',
        };
        updateSystemStatus('socket', STATUS_MAP.LOADING, reason);
      },
      onMessage: (ws, event) => {
        const reason = {
          message: '[Sockets] Live readings new message.',
          instance: 'cloud',
          response: event,
        };
        updateSystemStatus('socket', STATUS_MAP.ONLINE, reason);
      },
      onError: (ws, event) => {
        console.error('[Sockets] Error: Live readings errored.', event);

        const reason = {
          message: '[Sockets] Error: Live readings errored.',
          instance: 'cloud',
          event,
          ws,
        };
        updateSystemStatus('socket', STATUS_MAP.OFFLINE, reason);
      },
      onDisconnected: (ws, event) => {
        const reason = {
          message: '[Sockets] Live readings disconnected.',
          event,
          instance: 'cloud',
        };
        updateSystemStatus('socket', STATUS_MAP.LOADING, reason);
      },
    });
    stream.value.open();
  }
  function disconnect() {
    console.info('[socket] disconnect');
    stream.value?.close();
    url.value = undefined;
  }

  return { url, stream, connect, disconnect, connected };
}

/*
  Generates the WS API's based url returning either a local or cloud baseurl
*/
export async function buildWsUrl(
  app: ComponentInternalInstance,
  path = '',
): Promise<{ wsUrl: string; isLocal: boolean }> {
  const { getEnvironment } = useEnvironments();
  const systemsStore = useSystemsStore();
  const hubsStore = useHubStore();
  const hubService = systemsStore.getSystemsHubService;
  let wsUrl;
  if (hubService) {
    /*
    local api
    */
    // Replace the base API url with the local hub's ip and protocol
    const hubDomains = hubsStore.getHubUrl(hubService);
    wsUrl = new URL(`${hubDomains.wsUrl}`);
  } else {
    /*
  Cloud api
  */
    const { protocol, hostname, port } = new URL(
      getEnvironment.value.api.VITE_API_BASE_URL,
    );
    // When we abstract the protocol out form a new Url() it will have a : so we need to get rid of that.
    const wsProtocol = protocol.split(':')[0] === 'https' ? 'wss' : 'ws';
    wsUrl = new URL(`${wsProtocol}://${hostname}`);

    if (port) wsUrl.port = port;
  }

  wsUrl.pathname = path;
  /*
    Authorization: Get the token from auth0 to pass along in our request
  */
  const auth = app?.appContext.config.globalProperties.$auth0;
  try {
    if (auth) {
      const token = await auth.getAccessTokenSilently();
      if (token) {
        wsUrl.searchParams.set('Authorization', 'Bearer ' + token);
      }
    }
  } catch (e) {
    console.error(e);
    throw new Error('Error: Not Authenticated!');
  }
  return {
    wsUrl: wsUrl.toString(),
    isLocal: !!hubService,
  };
}
