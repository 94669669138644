import { useSystemsStore } from 'src/stores/systems';
import { Device as phone } from '@capacitor/device';
import { useStorage } from '@vueuse/core';
import { ZeroConfService } from '@luminsmart/capacitor-zeroconf';
import { useAxios } from '@vueuse/integrations/useAxios.mjs';
import { AxiosError, AxiosInstance } from 'axios';
import { useAuth } from '@luminsmart/building-blocks';
const LOCAL_AUTH_ERRORS = {
  NO_TOKEN_ID: 'hub token id not found',
  NO_SECRET_FOUND: 'hub token secret not found',
  TOKEN_EXPIRED: 'hub token expired',
};
export declare type LocalAuthToken = {
  id: string;
  secret: string;
  token_type: 'owner';
  gadget_model: string;
  user_id: string;
  name: string;
  created_at: string;
  expires_at: string | null;
};

const localAuthTokens = useStorage<Record<string, LocalAuthToken>>(
  'localAuthTokens',
  {},
);

export function useLocalAuth() {
  const systemsStore = useSystemsStore();
  const cloudAuth = useAuth();

  async function generateAuthToken(
    hub: ZeroConfService,
    instance: AxiosInstance,
  ) {
    const url = '/auth/api-keys';
    const system = systemsStore.getSelectedSystem;
    const timestamp = new Date().getTime();
    const userEmail = cloudAuth.provider.user.value?.email;
    const body = {
      name: userEmail + '_' + timestamp,
      gadget_model: (await phone.getInfo()).model,
      organization_id: system?.org_id,
      system_id: system?.id,
    };
    const token = await cloudAuth.getToken();

    // NOTE: this was intensional that we are not using the useApi layer
    const { execute } = useAxios(
      url,
      {
        method: 'POST',
        data: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      instance,
      { immediate: false },
    );
    try {
      const response = await execute();
      if (response) {
        localAuthTokens.value[hub.txtRecord.hub_id] =
          response.data.value.api_key;
        return response.data;
      }
    } catch (e) {
      console.error(
        '[useLocalAuth] - generateAuthToken() There was a problem generating the token',
        e,
      );
    }
  }

  async function getAuthToken(hub: ZeroConfService) {
    const hubId = hub.txtRecord.hub_id;
    const token = localAuthTokens.value[hubId];
    if (token) return token;
    throw 'No local token available';
  }

  function isLocalAuthError(e: AxiosError): boolean {
    const message = (e.response?.data as { error: { message?: string } }).error
      .message;
    if (LOCAL_AUTH_ERRORS.NO_TOKEN_ID === message) {
      return true;
    }
    if (LOCAL_AUTH_ERRORS.NO_SECRET_FOUND === message) {
      return true;
    }
    if (LOCAL_AUTH_ERRORS.TOKEN_EXPIRED === message) {
      return true;
    }

    return false;
  }

  return {
    localAuthTokens,
    generateAuthToken,
    getAuthToken,
    isLocalAuthError,
  };
}

// async function fetchAuthTokens(hub: ZeroConfService) {
//   const baseURL = hubsStore.getHubUrl(hub).url;
//   const url = '/auth/api-keys';
//   const config = request({
//     baseURL,
//     url,
//     method: 'GET',
//     instanceId: hub.txtRecord.hub_id,
//     fallbackToCloud: false,
//   });

//   return await config.execute().then((response) => {
//     console.log('fetchAuthTokens', response);
//     if (response) {
//       // localAuthTokens.value = response.data.value.api_keys;
//       return response.data;
//     }
//   });
// }
