<script setup lang="ts">
import { iconElement } from '@luminsmart/building-blocks';
import { useTimeoutFn } from '@vueuse/core';
import { useAppLoadingStatus } from 'src/composables/useAppLoadingStatus';
import { watch } from 'vue';
const { isAppLoading, setLoading } = useAppLoadingStatus();
// If the app is ever loading for more then 15 seconds then something is probably wrong and we should remove the loading state.
const { start, stop } = useTimeoutFn(() => {
  setLoading(false);
}, 15000);
watch(isAppLoading, () => {
  if (isAppLoading.value) {
    start();
  } else {
    stop();
  }
});
</script>

<template>
  <div class="fullscreen l-app-bg force-top">
    <div class="grid window-height">
      <div class="grid-center text-center">
        <iconElement name="logo" width="147" class="justify-content-center" />
        <div class="flex flex-center q-mt-lg">
          <q-img class="loading-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.force-top {
  z-index: 6000;
}
.loading-icon {
  min-width: 320px;
  width: 100%;
  mask: url('../../../loading.svg');
  mask-repeat: no-repeat;
  animation: animateBg 5s infinite;
  background-image: linear-gradient(
    90deg,
    var(--background-bg),
    var(--background-bg),
    var(--primary-bg),
    var(--background-bg)
  );
  background-size: 300% 100%;
}

@keyframes animateBg {
  0% {
    background-position: 0% 75%;
  }

  50% {
    background-position: 100% 75%;
  }

  100% {
    background-position: 0% 100%;
  }
}
</style>
