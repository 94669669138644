<template>
  <svg
    width="22"
    height="26"
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.16074 1.93201C5.37832 1.93201 4.74157 2.56778 4.74157 3.35117C4.74157 4.13359 5.37832 4.77034 6.16074 4.77034C6.94315 4.77034 7.57991 4.13359 7.57991 3.35117C7.57991 2.56778 6.94315 1.93201 6.16074 1.93201ZM6.16074 5.70137C4.86459 5.70137 3.81055 4.64732 3.81055 3.35117C3.81055 2.05503 4.86459 1 6.16074 1C7.45688 1 8.51191 2.05503 8.51191 3.35117C8.51191 4.64732 7.45688 5.70137 6.16074 5.70137Z"
      fill="#C4C4C4"
      stroke="#C4C4C4"
      stroke-width="0.5"
    />
    <path
      d="M16.3404 12.1713C15.558 12.1713 14.9213 12.808 14.9213 13.5904C14.9213 14.3728 15.558 15.0106 16.3404 15.0106C17.1228 15.0106 17.7606 14.3728 17.7606 13.5904C17.7606 12.808 17.1228 12.1713 16.3404 12.1713ZM16.3404 15.9416C15.0443 15.9416 13.9902 14.8866 13.9902 13.5904C13.9902 12.2943 15.0443 11.2402 16.3404 11.2402C17.6366 11.2402 18.6916 12.2943 18.6916 13.5904C18.6916 14.8866 17.6366 15.9416 16.3404 15.9416Z"
      fill="#C4C4C4"
      stroke="#C4C4C4"
      stroke-width="0.5"
    />
    <path
      d="M15.6476 1.93201C14.8642 1.93201 14.2274 2.56778 14.2274 3.35117C14.2274 4.13359 14.8642 4.77034 15.6476 4.77034C16.43 4.77034 17.0667 4.13359 17.0667 3.35117C17.0667 2.56778 16.43 1.93201 15.6476 1.93201ZM15.6476 5.70137C14.3514 5.70137 13.2964 4.64732 13.2964 3.35117C13.2964 2.05503 14.3514 1 15.6476 1C16.9437 1 17.9977 2.05503 17.9977 3.35117C17.9977 4.64732 16.9437 5.70137 15.6476 5.70137Z"
      fill="#C4C4C4"
      stroke="#C4C4C4"
      stroke-width="0.5"
    />
    <path
      d="M3.35117 10.5194C2.56876 10.5194 1.93201 11.1562 1.93201 11.9386C1.93201 12.721 2.56876 13.3577 3.35117 13.3577C4.13359 13.3577 4.77034 12.721 4.77034 11.9386C4.77034 11.1562 4.13359 10.5194 3.35117 10.5194ZM3.35117 14.2897C2.05503 14.2897 1 13.2347 1 11.9386C1 10.6424 2.05503 9.58838 3.35117 9.58838C4.64732 9.58838 5.70137 10.6424 5.70137 11.9386C5.70137 13.2347 4.64732 14.2897 3.35117 14.2897Z"
      fill="#C4C4C4"
      stroke="#C4C4C4"
      stroke-width="0.5"
    />
    <path
      d="M21.482 8.24597L21.482 8.24596L17.748 4.47069L17.6347 4.35609L17.5519 4.4944C17.3993 4.74941 17.1962 4.97028 16.9565 5.14564L16.8394 5.23128L16.9414 5.33442L20.4709 8.90388V24.5332C20.4709 24.8591 20.7355 25.1238 21.0614 25.1238C21.3881 25.1238 21.6529 24.8593 21.6529 24.5332V8.66159C21.6529 8.50557 21.5917 8.3569 21.482 8.24597Z"
      fill="#C4C4C4"
      stroke="#C4C4C4"
      stroke-width="0.25"
    />
    <path
      d="M8.2627 4.4707L8.14966 4.35639L8.06673 4.49411C7.91302 4.74936 7.70985 4.97029 7.47014 5.14564L7.35306 5.23129L7.45507 5.33443L10.9856 8.90389V24.5332C10.9856 24.8591 11.2502 25.1238 11.5761 25.1238C11.902 25.1238 12.1666 24.8591 12.1666 24.5332V8.66159C12.1666 8.50557 12.1053 8.3569 11.9956 8.24597L11.9068 8.33386L11.9956 8.24597L8.2627 4.4707Z"
      fill="#C4C4C4"
      stroke="#C4C4C4"
      stroke-width="0.25"
    />
    <path
      d="M15.8993 15.7725L15.7495 15.7422V15.895V24.535C15.7495 24.8609 16.0141 25.1255 16.34 25.1255C16.6667 25.1255 16.9315 24.8611 16.9315 24.535V15.895V15.7423L16.7819 15.7725C16.6379 15.8015 16.4907 15.8173 16.34 15.8173C16.1894 15.8173 16.0422 15.8015 15.8993 15.7725Z"
      fill="#C4C4C4"
      stroke="#C4C4C4"
      stroke-width="0.25"
    />
    <path
      d="M7.25774 15.4163L7.25739 15.4158L5.35302 13.2261L5.24953 13.1071L5.15731 13.235C4.98396 13.4755 4.76406 13.6795 4.50976 13.8341L4.38314 13.9111L4.48038 14.0229L6.22121 16.0249V24.5335C6.22121 24.8592 6.48466 25.124 6.81172 25.124C7.13762 25.124 7.40223 24.8594 7.40223 24.5335V15.804C7.40223 15.6616 7.35098 15.5244 7.25774 15.4163Z"
      fill="#C4C4C4"
      stroke="#C4C4C4"
      stroke-width="0.25"
    />
  </svg>
</template>
