import { AxiosHeaders, AxiosInstance } from 'axios';
import { App } from 'vue';
import { api } from '../api';
import { captureException } from '@sentry/vue';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

export function setupAxios(app: App<Element>) {
  // If we're in development mode and the .env file says
  // to SKIP_AUTH, don't run this interceptor for Auth0
  // TODO: Figure out if this should be moved
  if (import.meta.env.DEV && import.meta.env.VITE_SKIP_AUTH) return;

  api.interceptors.request.use(async (config) => {
    if (!config.url) return config;

    // ---------------------
    // lumin - Authorization
    // ---------------------
    const { getAccessTokenSilently } = app.config.globalProperties.$auth0;
    try {
      const token = await getAccessTokenSilently();
      if (token) {
        if (!config.headers) config.headers = {} as AxiosHeaders;
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (res) {
      captureException(res);
      console.error(
        'Auth0 Axios interceptor, the token could not be retrieved from auth0',
        res,
      );
    }

    return config;
  });
}
