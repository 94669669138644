<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_18519_70898)">
      <path
        d="M18.4206 19H1V1.57952C1 1.25982 1.25975 1 1.57937 1C1.89899 1 2.15874 1.25982 2.15874 1.57952V17.841H18.4206C18.7403 17.841 19 18.1008 19 18.4205C19 18.7402 18.7403 19 18.4206 19Z"
        fill="currentColor"
      />
      <path
        d="M13.9468 3.68145C13.7112 3.4651 13.3443 3.48152 13.128 3.71719L11.2808 5.73297L11.275 1.59614C11.275 1.27643 11.0152 1.01758 10.6956 1.01758C10.376 1.01758 10.1163 1.2774 10.1172 1.59807L10.123 5.732L8.31056 3.77901C8.0933 3.5443 7.72636 3.53078 7.49172 3.7481C7.25708 3.96542 7.24356 4.33245 7.46082 4.56716L10.7111 8.06941L13.9826 4.49858C14.1989 4.26291 14.1825 3.89588 13.9468 3.67952V3.68145Z"
        fill="currentColor"
      />
      <path
        d="M5.36942 15.4096C5.28831 15.4096 5.20527 15.3922 5.12705 15.3565C4.8364 15.2222 4.70991 14.8774 4.84413 14.5876L5.04691 14.1491C5.18113 13.8584 5.52585 13.7319 5.81554 13.8661C6.10619 14.0004 6.23268 14.3452 6.09846 14.635L5.89568 15.0735C5.79815 15.285 5.58862 15.4096 5.36942 15.4096Z"
        fill="var(--q-primary)"
      />
      <path
        d="M6.33793 13.3115C6.25682 13.3115 6.17377 13.2941 6.09556 13.2584C5.80491 13.1241 5.67841 12.7793 5.81263 12.4896L6.19598 11.6608C6.3302 11.3701 6.67493 11.2436 6.96461 11.3778C7.25526 11.5121 7.38176 11.8569 7.24754 12.1467L6.86419 12.9754C6.76666 13.1869 6.55712 13.3115 6.33793 13.3115Z"
        fill="var(--q-primary)"
      />
      <path
        d="M7.48807 10.8251C7.40696 10.8251 7.32392 10.8077 7.24571 10.772C6.95506 10.6377 6.82856 10.2929 6.96278 10.0031L7.32102 9.22852H8.17463C8.49424 9.22852 8.75399 9.48834 8.75399 9.80804C8.75399 10.1277 8.49424 10.3876 8.17463 10.3876H8.06261L8.0153 10.4899C7.91777 10.7015 7.70823 10.8261 7.48904 10.8261L7.48807 10.8251Z"
        fill="var(--q-primary)"
      />
      <path
        d="M11.2443 10.3856H10.2207C9.90111 10.3856 9.64136 10.1258 9.64136 9.80609C9.64136 9.48638 9.90111 9.22656 10.2207 9.22656H11.2443C11.5639 9.22656 11.8236 9.48638 11.8236 9.80609C11.8236 10.1258 11.5639 10.3856 11.2443 10.3856Z"
        fill="var(--q-primary)"
      />
      <path
        d="M13.9701 10.828C13.748 10.828 13.5356 10.6995 13.44 10.4822L13.3975 10.3856H13.2923C12.9726 10.3856 12.7129 10.1258 12.7129 9.80609C12.7129 9.48638 12.9726 9.22656 13.2923 9.22656H14.1526L14.5002 10.0137C14.6296 10.3064 14.4973 10.6483 14.2048 10.7778C14.1285 10.8116 14.0493 10.827 13.9711 10.827L13.9701 10.828Z"
        fill="var(--q-primary)"
      />
      <path
        d="M15.068 13.3114C14.846 13.3114 14.6335 13.183 14.5379 12.9656L14.172 12.1379C14.0426 11.8452 14.1749 11.5033 14.4674 11.3739C14.76 11.2444 15.1018 11.3768 15.2312 11.6694L15.5972 12.4972C15.7266 12.7898 15.5943 13.1318 15.3017 13.2612C15.2254 13.295 15.1463 13.3104 15.068 13.3104V13.3114Z"
        fill="var(--q-primary)"
      />
      <path
        d="M15.996 15.4094C15.7739 15.4094 15.5615 15.281 15.4659 15.0637L15.2708 14.6223C15.1414 14.3296 15.2737 13.9877 15.5663 13.8582C15.8589 13.7288 16.2007 13.8611 16.3301 14.1538L16.5252 14.5952C16.6546 14.8879 16.5223 15.2298 16.2297 15.3592C16.1534 15.393 16.0742 15.4085 15.996 15.4085V15.4094Z"
        fill="var(--q-primary)"
      />
    </g>
    <defs>
      <clipPath id="clip0_18519_70898">
        <rect width="18" height="18" fill="white" transform="translate(1 1)" />
      </clipPath>
    </defs>
  </svg>
</template>
