import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useApi } from 'src/composables/api/useApi';
import { mergeRecord } from '../util';
import { Automation } from 'src/types/Automation';
import { Intent } from 'src/types/Intent';
import { Device } from 'src/types/Device';
import { useDevicesStore } from 'src/stores/devices';
import { ZeroConfService } from '@luminsmart/capacitor-zeroconf';
export const useAutomationsStore = defineStore('automations', () => {
  const deviceStore = useDevicesStore();
  const { request } = useApi();
  const automations = ref<Automation[]>([]);
  function $reset() {
    automations.value = [];
  }

  function getAutomationByIntent(intent: Intent) {
    return automations.value.find((automation) =>
      automation.intents.find((i) => i.id === intent.id),
    );
  }

  async function fetchAutomations(systemId: string) {
    const config = request({
      url: `/systems/${systemId}/automations`,
      method: 'GET',
    });
    return await config.execute().then((response) => {
      if (response) automations.value = response.data.value;

      return automations.value;
    });
  }

  async function fetchAutomation(systemId: string, automationsId: string) {
    const config = request({
      url: `/systems/${systemId}/automations/${automationsId}`,
      method: 'GET',
    });
    return await config.execute().then((response) => {
      if (response) {
        automations.value = mergeRecord(automations.value, response.data.value);
        return response.data.value;
      } else {
        return null;
      }
    });
  }

  async function createAutomation(systemId: string, template: any) {
    const body = {
      type: template.type,
      name: template.name,
      conditions: template.conditions,
      actions: template.actions,
    };

    const config = request({
      url: `/systems/${systemId}/automations`,
      method: 'POST',
      data: body,
    });
    return await config.execute().then((response) => {
      if (response) {
        automations.value = mergeRecord(automations.value, response.data.value);
        return response.data.value;
      } else {
        return null;
      }
    });
  }

  async function updateAutomation(systemId: string, automation: Automation) {
    // convert devices to device ids so the API will accept the request
    automation.actions.forEach((action, index) => {
      if (action.devices) {
        const deviceIds: string[] = [];
        action.devices.forEach((device) => deviceIds.push(device.id));
        automation.actions[index] = {
          ...action,
          device_ids: deviceIds,
        };
      }
    });
    const config = request({
      url: `/systems/${systemId}/automations/${automation.type}/${automation.id}`,
      method: 'PATCH',
      data: automation,
    });

    return await config.execute().then((response) => {
      if (response) {
        automations.value = mergeRecord(automations.value, response.data.value);

        return response.data.value;
      } else {
        return null;
      }
    });
  }

  async function deleteAutomation(systemId: string, automation: Automation) {
    const config = request({
      url: `/systems/${systemId}/automations/${automation.id}`,
      method: 'DELETE',
    });
    return await config.execute().then((response) => {
      if (response) {
        automations.value = automations.value.filter(
          (a) => a.id !== response.data.value.id,
        );
        return response.data.value;
      } else {
        return null;
      }
    });
  }

  // This endpoint can only be used locally
  async function createElectrificationMode(
    systemId: string,
    devices: Device[],
    hub: ZeroConfService,
    service_limit: number,
    setpoint: number,
  ) {
    // Loop over all devices on the system set electrification_mode to true if that device was passed in above.
    for (const index in deviceStore.devices) {
      const device = deviceStore.devices[index];
      const types = device.components.map((c: any) =>
        c?.type?.type ? c.type.type : c.type,
      );

      // Check if this device is one one of the devices we want to set electrification_mode to true
      const isSelectedForElectrification = devices.find(
        (d) => d.id == device.id,
      );
      // Update the device if needed
      if (device.electrification_mode !== !!isSelectedForElectrification) {
        await deviceStore.patchDevice({
          id: device.id,
          system_id: systemId,
          name: device.name,
          component_types: types,
          electrification_mode: !!isSelectedForElectrification,
        });
      }
    }

    // Then create the mode
    const config = request({
      url: `/systems/${systemId}/overload-control`,
      method: 'PUT',
      instanceId: hub.txtRecord.hub_id,
      data: {
        service_limit: service_limit,
        setpoint: setpoint,
      },
    });
    await config.execute();
  }

  return {
    automations,
    getAutomationByIntent,
    fetchAutomations,
    fetchAutomation,
    createAutomation,
    updateAutomation,
    deleteAutomation,
    createElectrificationMode,
    $reset,
  };
});
