<script lang="ts">
import { computed, ref } from 'vue';
import { useAnalyticsStore } from 'src/stores/useAnalyticsStore';
import { QBtn } from 'quasar';

export default {
  name: 'ButtonElement',
  props: {
    label: { type: String as () => QBtn['label'] },
    class: {},
    type: { type: String as () => QBtn['type'] },
    unelevated: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    loading: { type: Boolean as () => QBtn['loading'] },
    loadingText: { type: String },
    eventName: { type: String, default: 'button' },
    disabled: { type: Boolean as () => QBtn['loading'] },
  },
  setup(props, {}) {
    const analyticsStore = useAnalyticsStore();
    const isDisabled = computed(() => !!props.disabled || !!props.loading);
    const classes = computed(() => props.class);
    const btn = ref('btn');
    if (props.unelevated || props.flat) {
      btn.value = 'btn-flat';
    }
    return {
      classes,
      btn,
      isDisabled,
      onClick: async () => {
        analyticsStore.trackEvent(props.eventName, {});
      },
    };
  },
};
</script>

<template>
  <q-btn
    @click="onClick"
    :class="`lumin-btn ${btn} ${classes}`"
    :label="label"
    :type="type"
    :flat="flat"
    :unelevated="unelevated"
    :loading="loading"
    :disable="isDisabled"
  >
    <slot></slot>
    <template v-slot:loading>
      <q-spinner class="q-mr-md on-left" size="20px" />
      <div>{{ loadingText }}</div>
    </template>
  </q-btn>
</template>

<style scoped></style>
