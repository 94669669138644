// FOR_PLOP: LayoutImports

// FOR_PLOP: PageImports

import type { RouteRecordRaw } from 'vue-router';
import { setupRoutes } from './setup.routes';
import { unauthenticatedRoutes } from './unauthenticated.routes';
import { homeownerRoutes } from './homeonwer.routes';
import { organizationRoutes } from './organization.routes';

const routes: RouteRecordRaw[] = [
  {
    name: 'Root',
    path: '/',
    children: [
      ...unauthenticatedRoutes,
      ...setupRoutes,
      ...homeownerRoutes,
      ...organizationRoutes,
    ],
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
