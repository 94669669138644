import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useApi } from 'src/composables/api/useApi';
import { UtilityEvent } from 'src/types/Automation';
import { mergeRecord } from '../util';
import { useAppBanners } from 'src/composables/useAppBanners';
import { Intent } from 'src/types/Intent';
export const useUtilityEventsStore = defineStore('utilityEvents', () => {
  const { request } = useApi();
  const appBanners = useAppBanners();
  const utilityEvents = ref<UtilityEvent[]>([]);

  function $reset() {
    utilityEvents.value = [];
  }

  // State
  const activeUtilityEvents = computed(() =>
    utilityEvents.value.filter((a) => a.status === 'in_progress'),
  );
  const enabledUtilityEvents = computed(() =>
    utilityEvents.value.filter(
      (a) => a?.automation?.enabled && a.status == 'scheduled',
    ),
  );
  const completedUtilityEvents = computed(() =>
    utilityEvents.value.filter(
      (a) => a?.automation?.enabled && a.status == 'completed',
    ),
  );
  const disabledUtilityEvents = computed(() =>
    utilityEvents.value.filter(
      (a) => !a?.automation?.enabled || a.status === 'opted_out',
    ),
  );

  watch(activeUtilityEvents, () => {
    if (activeUtilityEvents.value.length > 0) {
      appBanners.createBanner({
        id: 'UTILITY_CONTROL_EVENT',
        platforms: [],
        version: null,
        title: 'Utility Control Event in progress',
        description: '',
        classes: 'bg-lumin-blue-transparent text-white',
        url: null,
        icon: 'info',
        closable: true,
        callout: false,
      });
    } else {
      appBanners.removeBanner('UTILITY_CONTROL_EVENT');
    }
  });

  /*
   * Getters
   */

  const getUtilityEvent = (utilityEventId: string) => {
    return utilityEvents?.value?.find((event) => event.id === utilityEventId);
  };

  function getUtilityEventByIntentId(intent: Intent) {
    const foundEvent = utilityEvents?.value?.find((event) => {
      const foundAutomationForIntentId = event.automation?.intents?.find(
        (i) => i.id === intent.id,
      );
      if (foundAutomationForIntentId) return event;
    });
    return foundEvent;
  }

  /*
   * Actions
   */

  function fetchUtilityEvents(systemId: string) {
    if (utilityEvents.value)
      utilityEvents.value = utilityEvents?.value?.filter(
        (event) => event.system_id === systemId,
      );
    const config = request({
      url: `/systems/${systemId}/fleet-control-events`,
      method: 'GET',
    });
    config.execute().then((response) => {
      if (response) utilityEvents.value = response.data.value;
    });

    return config;
  }

  function fetchUtilityEvent(systemId: string, utilityEventId: string) {
    const config = request({
      url: `/systems/${systemId}/fleet-control-events/${utilityEventId}`,
      method: 'GET',
    });
    config.execute().then((response) => {
      if (response)
        utilityEvents.value = mergeRecord(
          utilityEvents.value,
          response.data.value,
        );
    });

    return config;
  }

  async function optOutDevice(
    systemId: string,
    utilityEventId: string,
    deviceId: string,
  ) {
    const config = request({
      url: `/systems/${systemId}/fleet-control-events/${utilityEventId}/opt-out`,
      method: 'PUT',
      data: { deviceIds: [deviceId] },
    });
    const response = await config.execute();
    if (response) {
      utilityEvents.value = mergeRecord(
        utilityEvents.value,
        response.data.value,
      );
    }
    return config;
  }

  /*
   * Helpers
   */

  function parseToAutomations(utilityEvents: UtilityEvent[]) {
    return utilityEvents?.map((evt) => {
      // put the end time in the condtion...
      const conditions = evt.automation?.conditions?.map((condition) => {
        condition.data.end = evt.end;
      });
      // overwrite the Automation Id with hte utility event id because thats what we will use to navigate and fetch this "automation"
      return {
        ...evt.automation,
        id: evt.id,
        ...conditions,
        mandatory: evt.mandatory,
      };
    });
  }
  return {
    $reset,
    activeUtilityEvents,
    enabledUtilityEvents,
    completedUtilityEvents,
    disabledUtilityEvents,
    getUtilityEvent,
    getUtilityEventByIntentId,
    fetchUtilityEvents,
    fetchUtilityEvent,
    optOutDevice,
    parseToAutomations,
  };
});
