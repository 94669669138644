import { updateSystemStatus, STATUS_MAP } from '../useHealthCheck';
import { ref } from 'vue';
import {
  CapacitorWebsocket,
  ConnectedEvent,
  ErrorEvent,
  MessageEvent,
} from '@luminsmart/capacitor-websocket';
import { PluginListenerHandle } from '@capacitor/core';
import { createSharedComposable } from '@vueuse/core';
export const useNativeSocket = createSharedComposable(nativeSocket);

function nativeSocket(name = 'streaming') {
  const url = ref<string>();
  const stream = ref({ data: ref<null | object>(null) });
  const handlers = ref<PluginListenerHandle[]>([]);
  const connected = ref(false);
  const hasDisconnected = ref(false);

  const applyListeners = async () => {
    const handlersList = await Promise.all([
      CapacitorWebsocket.addListener(`${name}:message`, handleMessage),
      CapacitorWebsocket.addListener(`${name}:disconnected`, handleDisconnect),
      CapacitorWebsocket.addListener(`${name}:error`, handleError),
      CapacitorWebsocket.addListener(`${name}:connected`, handleConnect),
    ]);

    handlers.value = handlersList;
  };

  const cleanupListeners = async () => {
    if (handlers.value.length > 0) {
      await Promise.all(handlers.value.map((x) => x.remove()));
    }
  };

  const disconnect = async () => {
    try {
      sendMessage('disconnect');
      await CapacitorWebsocket.disconnect({ name });
      await cleanupListeners();

      connected.value = false;
      hasDisconnected.value = false;

      const reason = {
        message: '[NativeSockets] Error: Live readings disconnected.',
      };
      updateSystemStatus('socket', STATUS_MAP.LOADING, reason);
    } catch (e) {
      console.error('error disconnecting', e);
    }
  };

  const connect = async (headers = {}) => {
    if (!url.value) {
      console.warn('useNativeSocket: connect', 'URL is not set', url.value);
      return;
    }
    name = Date.now().toString();
    try {
      await applyListeners();
      await CapacitorWebsocket.build({
        name,
        url: url.value,
        headers,
      });
      await CapacitorWebsocket.applyListeners({ name });
      await CapacitorWebsocket.connect({ name });
      connected.value = true;
    } catch (e) {
      console.error('useNative sockets', e);
      const reason = {
        message: '[NativeSockets] connect - catch:',
        e,
        instance: name,
      };
      updateSystemStatus('socket', STATUS_MAP.OFFLINE, reason);
    }
  };

  const handleMessage = (message: MessageEvent) => {
    try {
      stream.value.data = ref(message.data);
      const reason = {
        message: '[NativeSockets] new message.',
        response: message,
        instance: name,
      };
      updateSystemStatus('socket', STATUS_MAP.ONLINE, reason);
    } catch (e) {
      console.error(e);
    }
  };

  const handleConnect = (event: ConnectedEvent) => {
    console.info('Connected!', event);
    const reason = {
      message: '[NativeSockets] connected.',
      response: {},
      instance: name,
    };
    updateSystemStatus('socket', STATUS_MAP.LOADING, reason);
  };

  const handleDisconnect = () => {
    console.info('Disconnected!');
    disconnect();
  };

  const handleError = (error: ErrorEvent) => {
    hasDisconnected.value = true;
    connected.value = false;

    console.error('[NativeSockets] - handleError socket error! ', error);

    const reason = {
      message: '[NativeSockets] Error:',
      error,
      instance: name,
    };
    updateSystemStatus('socket', STATUS_MAP.OFFLINE, reason);
  };

  const sendMessage = (data: unknown) => {
    const options = { name, data };
    CapacitorWebsocket.send(options);
  };

  return { url, stream, connect, disconnect, connected };
}
