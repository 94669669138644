<template>
  <svg
    width="34"
    height="33"
    viewBox="0 0 34 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.9309 20.2605C28.9309 16.122 25.5273 12.7393 21.3633 12.7393C17.1993 12.7393 13.7957 16.122 13.7957 20.2605V32.9279H16.8372V20.2605C16.8372 17.7774 18.8649 15.7622 21.3633 15.7622C23.8617 15.7622 25.8894 17.7774 25.8894 20.2605V32.9279H28.9309V20.2605ZM9.26956 13.7469H6.22803V32.9639H9.26956V13.7469Z"
      fill="currentColor"
    />
    <path
      d="M32.5879 12.1276L17.0181 3.3108L1.41214 12.1276L0 9.60851L17.0181 0L34 9.60851L32.5879 12.1276Z"
      fill="var(--q-primary)"
    />
    <path
      d="M31.2842 31.7044C31.2842 31.4884 31.3566 31.2725 31.4652 31.0926C31.5738 30.9127 31.7187 30.7327 31.9359 30.6248C32.1532 30.5168 32.3342 30.4448 32.5877 30.4448C32.8049 30.4448 33.0222 30.5168 33.2032 30.6248C33.3843 30.7327 33.5653 30.8767 33.674 31.0926C33.7826 31.3085 33.855 31.4884 33.855 31.7404C33.855 31.9563 33.7826 32.1722 33.674 32.3521C33.5653 32.5321 33.4205 32.712 33.2032 32.82C33.0222 32.9279 32.8049 32.9999 32.5515 32.9999C32.3342 32.9999 32.117 32.9279 31.8997 32.82C31.7187 32.712 31.5376 32.5321 31.429 32.3521C31.3566 32.1362 31.2842 31.9563 31.2842 31.7044ZM31.4652 31.7044C31.4652 31.8843 31.5014 32.0642 31.6101 32.2442C31.7187 32.4241 31.8273 32.5321 32.0084 32.64C32.1894 32.748 32.3704 32.784 32.5515 32.784C32.7325 32.784 32.9136 32.748 33.0946 32.64C33.2757 32.5321 33.3843 32.4241 33.4929 32.2442C33.6015 32.0642 33.6377 31.8843 33.6377 31.7044C33.6377 31.5244 33.6015 31.3445 33.4929 31.1646C33.3843 30.9846 33.2757 30.8767 33.0946 30.7687C32.9136 30.6607 32.7325 30.6248 32.5515 30.6248C32.3704 30.6248 32.1894 30.6607 32.0084 30.7687C31.8273 30.8767 31.7187 30.9846 31.6101 31.1646C31.5014 31.3445 31.4652 31.5244 31.4652 31.7044ZM33.0946 31.4165C33.0946 31.4884 33.0584 31.5964 33.0222 31.6684C32.986 31.7404 32.9136 31.7763 32.8049 31.8123L33.2032 32.4601H32.9136L32.5515 31.8843H32.3342V32.4601H32.0808V30.9846H32.5153C32.6963 30.9846 32.8412 31.0206 32.9136 31.0926C33.0222 31.1646 33.0946 31.2725 33.0946 31.4165ZM32.3342 31.6684H32.5153C32.5877 31.6684 32.6601 31.6324 32.7325 31.5964C32.8049 31.5604 32.8049 31.4884 32.8049 31.4165C32.8049 31.3445 32.7687 31.2725 32.7325 31.2365C32.6963 31.2006 32.6239 31.1646 32.5153 31.1646H32.3342V31.6684Z"
      fill="currentColor"
    />
  </svg>
</template>
