<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" />
    <path
      d="M19.588 7.76708C19.2929 7.45703 18.7977 7.43944 18.4825 7.7297L16.8505 9.22829L16.8359 5.8397C16.8348 5.4153 16.4849 5.07227 16.0534 5.07227H16.0501C15.6175 5.07337 15.2687 5.4197 15.2698 5.8441L15.2855 9.26567L13.6468 7.733C13.3338 7.44054 12.8386 7.45263 12.5401 7.76049C12.2428 8.06834 12.2551 8.55541 12.568 8.84897L16.03 12.0869L19.5511 8.85336C19.8675 8.5631 19.8842 8.07604 19.5891 7.76598L19.588 7.76708Z"
      fill="currentColor"
    />
    <path
      d="M25.0163 25.9998H5.33581C4.59804 25.9998 4 25.4116 4 24.6859V5.96625C4 5.46819 4.3823 5.0339 4.88644 5.00201C5.43976 4.96683 5.90031 5.39782 5.90031 5.93437V24.1307H25.0498C25.5953 24.1307 26.0335 24.5837 25.9978 25.1279C25.9653 25.6249 25.5227 25.9998 25.0174 25.9998H25.0163Z"
      fill="currentColor"
    />
    <path
      d="M8.573 22.4207C8.41203 22.4207 8.24995 22.3844 8.09681 22.3085C7.57254 22.049 7.36239 21.4212 7.62508 20.9056L7.64967 20.8561C7.91348 20.3404 8.55176 20.1337 9.07602 20.3932C9.60029 20.6527 9.81044 21.2805 9.54775 21.7962L9.52316 21.8456C9.33648 22.2107 8.96201 22.4207 8.57412 22.4207H8.573Z"
      fill="currentColor"
    />
    <path
      d="M10.2844 19.0728C10.1246 19.0728 9.96138 19.0365 9.80823 18.9618C9.28397 18.7023 9.0727 18.0745 9.33651 17.5588L9.38011 17.4742C9.64391 16.9585 10.2822 16.7507 10.8053 17.0102C11.3296 17.2697 11.5409 17.8975 11.2771 18.4131L11.2335 18.4978C11.0468 18.8628 10.6723 19.0728 10.2833 19.0728H10.2844Z"
      fill="currentColor"
    />
    <path
      d="M12.0137 15.6893C11.826 15.6893 11.6348 15.6398 11.4593 15.5353C10.9831 15.2495 10.8311 14.6349 11.0815 14.1445V14.1423C11.2682 13.7795 11.6449 13.5508 12.0585 13.5508C12.6464 13.5508 13.1618 14.0236 13.1573 14.6019C13.1551 14.9922 12.9348 15.3308 12.6118 15.5089C12.4363 15.6266 12.2273 15.6904 12.0137 15.6904V15.6893Z"
      fill="currentColor"
    />
    <path
      d="M16.1742 15.6398H16.0724C15.4856 15.6398 15.0105 15.1725 15.0105 14.5953C15.0105 14.0181 15.4856 13.5508 16.0724 13.5508H16.1742C16.761 13.5508 17.2361 14.0181 17.2361 14.5953C17.2361 15.1725 16.761 15.6398 16.1742 15.6398Z"
      fill="currentColor"
    />
    <path
      d="M20.2319 15.6904C20.0162 15.6904 19.8049 15.6255 19.6272 15.5046C19.3064 15.3253 19.0895 14.9867 19.0884 14.5975C19.0873 14.0214 19.6015 13.5508 20.1872 13.5508C20.6064 13.5508 20.9876 13.7861 21.1698 14.1577L21.172 14.161C21.4135 14.6514 21.2559 15.2572 20.7808 15.5386C20.6064 15.642 20.4175 15.6904 20.2308 15.6904H20.2319Z"
      fill="currentColor"
    />
    <path
      d="M21.8997 19.0726C21.5051 19.0726 21.1273 18.856 20.944 18.4833L20.9026 18.3987C20.6467 17.8797 20.8669 17.2552 21.3945 17.0034C21.9221 16.7516 22.557 16.9682 22.813 17.4872L22.8544 17.5719C23.1104 18.0908 22.8901 18.7153 22.3625 18.9671C22.2127 19.0386 22.0551 19.0715 21.8997 19.0715V19.0726Z"
      fill="currentColor"
    />
    <path
      d="M23.5496 22.4201C23.1551 22.4201 22.7761 22.2035 22.5928 21.8307L22.5682 21.7813C22.3122 21.2623 22.5335 20.6378 23.0612 20.386C23.5888 20.1342 24.2237 20.3519 24.4797 20.8709L24.5043 20.9204C24.7603 21.4393 24.5389 22.0638 24.0113 22.3156C23.8626 22.3871 23.7039 22.4201 23.5496 22.4201Z"
      fill="currentColor"
    />
  </svg>
</template>
