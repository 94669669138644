<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM6.57143 4.625V3.8125C6.57143 3.36309 6.95446 3 7.42857 3C7.90268 3 8.28572 3.36309 8.28572 3.8125V4.625H11.7143V3.8125C11.7143 3.36309 12.0973 3 12.5714 3C13.0455 3 13.4286 3.36309 13.4286 3.8125V4.625H14.7143C15.4241 4.625 16 5.1709 16 5.84375V7.0625H4V5.84375C4 5.1709 4.57589 4.625 5.28571 4.625H6.57143ZM4 14.7812V7.875H16V14.7812C16 15.4541 15.4241 16 14.7143 16H5.28571C4.57589 16 4 15.4541 4 14.7812Z"
      fill="var(--q-info)"
      fill-opacity="0.9"
    />
  </svg>
</template>
