import { createPinia } from 'pinia';
import localforage from 'localforage';

const pinia = createPinia();

// N.B. (Ben,2023-09-24) explicit any here for localforage compatibility
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function indexDbPlugin({ store }: { store: any }) {
  const stored: string | null = await localforage.getItem(store.$id + '-state');
  if (stored) {
    store.$patch(deserialize(stored));
  }
  store.$subscribe(() => {
    localforage.setItem(store.$id + '-state', serialize({ ...store.$state }));
  });
}
pinia.use(indexDbPlugin);

export default pinia;

// Serialization Function: Converts a JavaScript object to a JSON string
function serialize(obj: object) {
  try {
    return JSON.stringify(obj);
  } catch (error) {
    console.error('Failed to serialize object', error);
    return null;
  }
}

// Deserialization Function: Converts a JSON string back to a JavaScript object
function deserialize(jsonString: string | null) {
  try {
    if (jsonString) return JSON.parse(jsonString);
  } catch (error) {
    console.error('Failed to deserialize JSON string', error);
    return null;
  }
}
