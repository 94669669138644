import { RouteRecordRaw } from 'vue-router';

export const generalSettingsRoute: RouteRecordRaw[] = [
  //////////////////////
  // || Settings
  /////////////////////
  {
    name: 'Menu',
    path: '/settings/:system_id?',
    component: () => import('pages/Menu.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'AccountSettings',
    path: '/settings/account',
    component: () => import('src/pages/settings/account/AccountSettings.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'ProfileSettings',
    path: '/settings/account/profile',
    component: () => import('src/pages/settings/account/ProfileSettings.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'ResetPassword',
    path: '/settings/account/reset-password',
    component: () => import('src/pages/settings/account/ResetPassword.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'NotificationSettings',
    path: 'settings/account/notification-settings',
    component: () => import('pages/settings/NotificationSettingsPage'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'DeleteAccount',
    path: '/settings/account/delete-account',
    component: () => import('src/pages/settings/account/DeleteAccount.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
];
