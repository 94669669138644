export {
  handleRequestErrors,
  notifyResponseErrors,
} from './handleRequestErrors';
export { sortByNestedKey } from './sortBy';
export { sleep } from './sleep';
export function mergeRecord<T extends { id: string }>(
  /** The state you want to update */
  state: Array<T>,
  /** The actual record we are looking to update in our loacl state */
  record: T,
): Array<T> {
  if (state.find((e) => e.id === record.id))
    state.forEach((r, index: number) => {
      if (r.id == record.id) {
        state[index] = record;
      }
    });
  else state.push(record);
  return state;
}

export function removeRecord<T extends { id: string }>(
  state: Array<T>,
  record: T,
) {
  return state.filter((r) => r.id !== record.id);
}

export function hashCode(s: string): number {
  return s.split('').reduce((a: number, b: string) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

/**
 * Compare semantic version strings to see if the current version (a) is greater then the target version (b)
 * @param {string} a - Semantic version 1
 * @param {string} b - Semantic version 2
 * @returns {number} - 1 if a > b
 *                     0 if a = b
 *                    -1 if a < b
 */
export function semverCompare(a: string, b: string) {
  if (a.startsWith(b + '-')) return -1;
  if (b.startsWith(a + '-')) return 1;
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'case',
    caseFirst: 'upper',
  });
}

// Tests for an empty object "{}"
export function isEmptyObject(obj: Record<string, unknown>) {
  return Object.keys(obj).length === 0;
}
