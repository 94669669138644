<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  name: string;
  size?: string;
  active?: boolean;
  color?: string;
}

const props = withDefaults(defineProps<Props>(), {
  size: '16px',
  active: false,
});

const isCustomSvg = computed(() => props.name.endsWith('.vue'));
const componentName = computed(() => props.name.split('.')[0]);
</script>

<template>
  <div
    v-if="isCustomSvg"
    :class="{ 'text-grey': !color, 'icon-active': active }"
    data-testid="custom-icon"
    :id="'custom-icon-' + name"
  >
    <component
      :is="componentName"
      :width="size"
      :height="size"
      :color="color"
    />
  </div>
  <q-icon
    v-else
    :name="name"
    :size="size"
    :color="color"
    :class="{ 'text-grey': !color, 'icon-active': active }"
    data-testid="icon-from-lib"
    :id="'icon-from-lib' + name"
  />
</template>
