<script lang="ts" setup>
import { useAuth } from 'src/composables/useAuth';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useBrowser } from 'src/composables/useBrowser';
import localforage from 'localforage';
import { $ResetPinia } from 'stores/util/ResetPinia';
import { useGadgetsStore } from 'stores/gadgets';
import { DEVICE_ID_KEY } from 'stores/gadgets/useGadgetsStore';
import LItem from '../elements/LItem.vue';
import { useAppLoadingStatus } from 'composables/useAppLoadingStatus';

const { logout, clientOptions } = useAuth();
const { openUrl } = useBrowser();
const { t } = useI18n();
const router = useRouter();
const gadgetsStore = useGadgetsStore();
const { setLoading } = useAppLoadingStatus();

async function signout() {
  setLoading(true);
  // Make sure we remove the Firebase token, fire the "remove device token" endpoint to clear the DB,
  // and then reset the localStorage items after they're being cleared
  const currentDeviceId = localStorage.getItem(DEVICE_ID_KEY);

  if (currentDeviceId) {
    await gadgetsStore.unregisterGadget(currentDeviceId);
  }

  await logout({
    logoutParams: {
      returnTo: clientOptions.authorizationParams.redirect_uri,
    },
    onRedirect: async (url) => {
      openUrl(url, '_self');
    },
  });

  localStorage.clear();

  // Restore device ID after clearing local storage, we want to persist between logins
  if (currentDeviceId) {
    localStorage.setItem(DEVICE_ID_KEY, currentDeviceId);
  }
  setLoading(false);

  localforage.clear();
  $ResetPinia();
  router.push({ name: 'Root' });
}
</script>

<template>
  <LItem
    :no-side-slot="true"
    @click="signout"
    :label="t('log-out')"
    icon="sym_r_logout"
  />
</template>
