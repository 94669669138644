<script setup lang="ts">
// The BarcodeScanner component should only be placed at the top level route (app.vue)
// to interact with it use the useCamera composable and call the scan function.
// This will render the barcode scanner UI.
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCamera } from 'src/composables/useCamera';

const { t } = useI18n();
const camera = useCamera();
const isScanning = computed(() => camera.isScanning.value);
const title = computed(() => t('scan-barcode'));
const lead = computed(() => t('scan-barcode-lead'));

// This call to init forces the Google barcode scanner to be downloaded
// if that is needed
camera.init();

function stopScan() {
  camera.stopScan();
}
</script>

<template>
  <div v-if="isScanning" data-testid="barcode-scanner" id="barcode-scanner">
    <div class="barcode-page">
      <div class="barcode-header">
        <div class="text-center text-h2 q-px-lg">
          {{ title }}
        </div>
        <div class="text-subtitle text-center q-pb-md q-pt-sm">
          {{ lead }}
        </div>
      </div>
      <div class="barcode-square"></div>
    </div>
  </div>
</template>

<style>
body.barcode-scanner-active {
  background: transparent;

  #q-app {
    .l-app-bg {
      background: transparent;
      background-color: transparent;
    }
  }
}

.barcode-square {
  /* new */
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  width: 300px;
  height: 300px;
  /* border: 3px solid white; */
  box-shadow: 0 0 0 4000px #2e323c;
  --b: 4px;
  --c: #f4b54a;
  --w: 30px;
  --r: 12px;
  padding: var(--b);
  box-sizing: border-box;
  display: inline-flex;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.barcode-square::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--c, #f4b54a);
  padding: var(--b);
  border-radius: var(--r);
  -webkit-mask:
    linear-gradient(0deg, #000 calc(2 * var(--b)), #0000 0) 50% var(--b) /
      calc(100% - 2 * var(--w)) 100% repeat-y,
    linear-gradient(-90deg, #000 calc(2 * var(--b)), #0000 0) var(--b) 50%/100%
      calc(100% - 2 * var(--w)) repeat-x,
    linear-gradient(#000 0 0) content-box,
    linear-gradient(#000 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.barcode-header {
  position: fixed;
  left: 50%;
  top: 15%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
