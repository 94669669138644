<template>
  <svg
    width="147"
    height="60"
    viewBox="0 0 147 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.7626 46.9763V24.0855H32.5282V46.9763C32.5282 50.9381 29.3501 54.0825 25.4866 54.0825C21.5608 54.0825 18.4451 50.8752 18.4451 46.9763V24.0855H13.2107V46.9763C13.2107 53.8309 18.6944 59.3649 25.4866 59.3649C32.2789 59.3649 37.7626 53.768 37.7626 46.9763ZM77.27 22.3247C73.0326 22.3247 69.2315 24.4 66.8635 27.6072C64.4956 24.4 60.6944 22.3247 56.457 22.3247C49.2908 22.3247 43.4332 28.2361 43.4332 35.468V57.6041H48.6053V35.468C48.6053 31.1288 52.095 27.6072 56.3947 27.6072C60.6944 27.6072 64.184 31.1288 64.184 35.468V57.6041H69.4184V35.468C69.4184 31.1288 72.908 27.6072 77.2077 27.6072C81.5074 27.6072 84.997 31.1288 84.997 35.468V57.6041H90.2315V35.468C90.2938 28.2361 84.4362 22.3247 77.27 22.3247ZM138.276 35.4051C138.276 28.1732 132.418 22.2618 125.252 22.2618C118.086 22.2618 112.228 28.1732 112.228 35.4051V57.5412H117.463V35.4051C117.463 31.066 120.953 27.5443 125.252 27.5443C129.552 27.5443 133.042 31.066 133.042 35.4051V57.5412H138.276V35.4051ZM104.439 24.0227H99.2047V57.6041H104.439V24.0227ZM5.23442 7.23193H0V57.6041H5.23442V7.23193Z"
      fill="currentColor"
    />
    <path
      d="M142.326 55.4032C142.326 55.0259 142.451 54.6485 142.638 54.3341C142.825 54.0197 143.074 53.7052 143.448 53.5166C143.822 53.3279 144.133 53.2021 144.569 53.2021C144.943 53.2021 145.317 53.3279 145.629 53.5166C145.94 53.7052 146.252 53.9568 146.439 54.3341C146.626 54.7114 146.751 55.0259 146.751 55.4661C146.751 55.8434 146.626 56.2207 146.439 56.5351C146.252 56.8496 146.003 57.164 145.629 57.3527C145.317 57.5413 144.943 57.6671 144.507 57.6671C144.133 57.6671 143.759 57.5413 143.386 57.3527C143.074 57.164 142.762 56.8496 142.575 56.5351C142.451 56.1578 142.326 55.8434 142.326 55.4032ZM142.638 55.4032C142.638 55.7176 142.7 56.032 142.887 56.3465C143.074 56.6609 143.261 56.8496 143.572 57.0382C143.884 57.2269 144.196 57.2898 144.507 57.2898C144.819 57.2898 145.13 57.2269 145.442 57.0382C145.753 56.8496 145.94 56.6609 146.127 56.3465C146.314 56.032 146.377 55.7176 146.377 55.4032C146.377 55.0887 146.314 54.7743 146.127 54.4599C145.94 54.1454 145.753 53.9568 145.442 53.7681C145.13 53.5795 144.819 53.5166 144.507 53.5166C144.196 53.5166 143.884 53.5795 143.572 53.7681C143.261 53.9568 143.074 54.1454 142.887 54.4599C142.7 54.7743 142.638 55.0887 142.638 55.4032ZM145.442 54.9001C145.442 55.0259 145.38 55.2145 145.317 55.3403C145.255 55.4661 145.13 55.529 144.943 55.5918L145.629 56.7238H145.13L144.507 55.7176H144.133V56.7238H143.697V54.1454H144.445C144.756 54.1454 145.006 54.2083 145.13 54.3341C145.317 54.4599 145.442 54.6485 145.442 54.9001ZM144.133 55.3403H144.445C144.569 55.3403 144.694 55.2774 144.819 55.2145C144.943 55.1516 144.943 55.0259 144.943 54.9001C144.943 54.7743 144.881 54.6485 144.819 54.5857C144.756 54.5228 144.632 54.4599 144.445 54.4599H144.133V55.3403Z"
      fill="currentColor"
    />
    <path
      d="M144.57 21.1928L117.775 5.78557L90.917 21.1928L88.4867 16.7907L117.775 0L147 16.7907L144.57 21.1928Z"
      fill="var(--q-primary)"
    />
  </svg>
</template>
