import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from 'capacitor-native-settings';
import { Platform } from 'quasar';

export function useSettings() {
  async function openSettings() {
    if (Platform.is.ios || Platform.is.android)
      return await NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App,
      });
  }
  return { openSettings };
}
