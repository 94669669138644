// This file handles environment related variables and can be used to swap to
// different environments live without rebuilding

import { useLocalStorage } from '@vueuse/core';
import { computed } from 'vue';

const id = import.meta.env.VITE_ENV;
const auth0 = {
  VITE_ANDROID_CALLBACK_URL: import.meta.env.VITE_ANDROID_CALLBACK_URL,
  VITE_IOS_CALLBACK_URL: import.meta.env.VITE_IOS_CALLBACK_URL,
  VITE_AUTH_DOMAIN: import.meta.env.VITE_AUTH_DOMAIN,
  VITE_AUTH_CLIENT_ID: import.meta.env.VITE_AUTH_CLIENT_ID,
  VITE_AUDIENCE: import.meta.env.VITE_AUDIENCE,
  VITE_AUTH_CONNECTION: import.meta.env.VITE_AUTH_CONNECTION, // only used to reset the pw...
};

const api = {
  VITE_API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  VITE_SUPPORT_API_URL: import.meta.env.VITE_SUPPORT_API_URL,
};

const analytics = {
  VITE_MIX_PANEL_KEY: import.meta.env.VITE_MIX_PANEL_KEY,
  VITE_PLAUSIBLE_TRACKING_DOMAIN: import.meta.env
    .VITE_PLAUSIBLE_TRACKING_DOMAIN,
};

const stores = {
  VITE_IOS_STORE_URL: import.meta.env.VITE_IOS_STORE_URL,
  VITE_ANDROID_STORE_URL: import.meta.env.VITE_ANDROID_STORE_URL,
};

const sentry = {
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  VITE_SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
};

type Environment = typeof environment.value;
// caches the env between sessions
const environment = useLocalStorage(
  'environment',
  {
    id,
    auth0,
    api,
    analytics,
    stores,
    sentry,
    version: 4,
  },
  { mergeDefaults: true },
);

export function useEnvironments() {
  const getEnvironment = computed(() => {
    // If theres a new version of the env config lets use it
    const env = environments.find((e) => {
      if (
        environment.value.id === e.id &&
        environment.value.version < e.version
      ) {
        return e;
      }
    });

    if (env) {
      environment.value = env;
      return env;
    }
    return environment.value;
  });

  function setEnvironment(env: Environment) {
    environment.value = env;

    alert(
      '👋 Hey friend, quit the app and restart to see this change take effect.',
    );
  }

  return {
    getEnvironment,
    setEnvironment,
    environments,
  };
}

const environments = [
  {
    id: 'prod',
    version: 4,
    auth0: {
      VITE_ANDROID_CALLBACK_URL:
        'com.luminsmart.octane://auth.luminsmart.io/capacitor/com.luminsmart.octane/callback',
      VITE_IOS_CALLBACK_URL:
        'octane://auth.luminsmart.io/capacitor/com.luminsmart.octane/callback',
      VITE_AUTH_DOMAIN: 'https://auth.luminsmart.io',
      VITE_AUTH_CLIENT_ID: 'kf0F8qFMqN5qDf9FjPx1d3qwmvoUHKQS',
      VITE_AUDIENCE: 'https://api.luminsmart.com',
      VITE_AUTH_CONNECTION: 'Lumin',
    },
    api: {
      VITE_API_BASE_URL: 'https://api.luminsmart.io',
      VITE_SUPPORT_API_URL: 'https://api.luminsmart.io/support',
    },
    analytics: {
      VITE_MIX_PANEL_KEY: 'ba9f55455efcd94ceb2fbc14eaf0c8df',
      VITE_PLAUSIBLE_TRACKING_DOMAIN: 'luminsmart.com',
    },
    stores: {
      VITE_IOS_STORE_URL: 'https://testflight.apple.com/join/cYmYKmYs',
      VITE_ANDROID_STORE_URL:
        'https://play.google.com/store/apps/details?id=com.luminsmart.octane',
    },
    sentry: {
      VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
      VITE_SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
    },
  },
  {
    id: 'dev',
    version: 4,
    auth0: {
      VITE_ANDROID_CALLBACK_URL:
        'com.luminsmart.octane://auth.dev.luminsmart.io/capacitor/com.luminsmart.octane/callback',
      VITE_IOS_CALLBACK_URL:
        'octane://auth.dev.luminsmart.io/com.luminsmart.octane/callback',
      VITE_AUTH_DOMAIN: 'https://auth.dev.luminsmart.io',
      VITE_AUTH_CLIENT_ID: 'LhfCX6VdoTSp5joiWUgOIcfyLtnwlKY1',
      VITE_AUDIENCE: 'https://api.luminsmart.com',
      VITE_AUTH_CONNECTION: 'Lumin',
    },
    api: {
      VITE_API_BASE_URL: 'https://api.dev.luminsmart.io',
      VITE_SUPPORT_API_URL: 'https://api.dev.luminsmart.io/support',
    },
    analytics: {
      VITE_MIX_PANEL_KEY: 'ba9f55455efcd94ceb2fbc14eaf0c8df',
      VITE_PLAUSIBLE_TRACKING_DOMAIN: 'luminsmart.com',
    },
    stores: {
      VITE_IOS_STORE_URL: 'https://testflight.apple.com/join/cYmYKmYs',
      VITE_ANDROID_STORE_URL:
        'https://play.google.com/store/apps/details?id=com.luminsmart.octane',
    },
    sentry: {
      VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
      VITE_SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
    },
  },
];
