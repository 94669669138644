import { Event } from 'src/types/Event';
import { useEventsStore } from '../events/useEventsStore';
import { useSystemsStore } from '../../stores/systems';

export default function handleEventsHistory(event: Event) {
  const eventsStore = useEventsStore();

  const systemsStore = useSystemsStore();

  if (!event?.data) return;
  const eventData = event?.data as Event;
  const config = eventsStore.updateEvents(
    systemsStore.getSelectedSystemId,
    eventData,
  );

  if (!config) {
    throw new Error('Error receiving new events');
  }
}
