<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.9">
      <path
        d="M12.4133 14.2051H0.253333C0.113421 14.2051 0 14.3146 0 14.4498V14.7556C0 14.8908 0.113421 15.0003 0.253333 15.0003H12.4133C12.5532 15.0003 12.6667 14.8908 12.6667 14.7556V14.4498C12.6667 14.3146 12.5532 14.2051 12.4133 14.2051Z"
        fill="#C4C4C4"
      />
      <path
        d="M11.1467 1.54199H1.52006C1.38014 1.54199 1.26672 1.65155 1.26672 1.78669V2.09256C1.26672 2.2277 1.38014 2.33726 1.52006 2.33726H11.1467C11.2866 2.33726 11.4001 2.2277 11.4001 2.09256V1.78669C11.4001 1.65155 11.2866 1.54199 11.1467 1.54199Z"
        fill="#C4C4C4"
      />
      <path
        d="M8.16995 4.41699H4.49662C4.35671 4.41699 4.24329 4.52655 4.24329 4.66169V4.96756C4.24329 5.1027 4.35671 5.21226 4.49662 5.21226H8.16995C8.30987 5.21226 8.42329 5.1027 8.42329 4.96756V4.66169C8.42329 4.52655 8.30987 4.41699 8.16995 4.41699Z"
        fill="#C4C4C4"
      />
      <path
        d="M8.48666 7.78223H4.24332C4.10341 7.78223 3.98999 7.89178 3.98999 8.02692V8.3328C3.98999 8.46794 4.10341 8.57749 4.24332 8.57749H8.48666C8.62657 8.57749 8.73999 8.46794 8.73999 8.3328V8.02692C8.73999 7.89178 8.62657 7.78223 8.48666 7.78223Z"
        fill="#C4C4C4"
      />
      <path
        d="M9.24671 11.3301H3.48337C3.34346 11.3301 3.23004 11.4396 3.23004 11.5748V11.8806C3.23004 12.0158 3.34346 12.1253 3.48337 12.1253H9.24671C9.38662 12.1253 9.50004 12.0158 9.50004 11.8806V11.5748C9.50004 11.4396 9.38662 11.3301 9.24671 11.3301Z"
        fill="#C4C4C4"
      />
      <path
        d="M9.48077 13.8982L3.56573 11.4537C3.4371 11.4005 3.28821 11.4581 3.23317 11.5824L3.1086 11.8636C3.05356 11.9878 3.11323 12.1317 3.24186 12.1848L9.15689 14.6294C9.28552 14.6826 9.43442 14.6249 9.48945 14.5007L9.61402 14.2195C9.66906 14.0952 9.6094 13.9514 9.48077 13.8982Z"
        fill="#C4C4C4"
      />
      <path
        d="M9.48417 11.3389L4.02754 8.04663C3.90888 7.97503 3.75259 8.00991 3.67847 8.12453L3.5107 8.38395C3.43658 8.49856 3.47269 8.64952 3.59135 8.72112L9.04797 12.0134C9.16664 12.085 9.32292 12.0502 9.39705 11.9355L9.56481 11.6761C9.63894 11.5615 9.60283 11.4105 9.48417 11.3389Z"
        fill="#C4C4C4"
      />
      <path
        d="M9.05936 8.21913L4.6271 4.63748C4.51979 4.55077 4.36002 4.5645 4.27025 4.66815L4.06705 4.90275C3.97727 5.0064 3.99149 5.16072 4.0988 5.24744L8.53106 8.82909C8.63837 8.91581 8.79814 8.90208 8.88791 8.79843L9.09111 8.56383C9.18089 8.46017 9.16667 8.30585 9.05936 8.21913Z"
        fill="#C4C4C4"
      />
      <path
        d="M1.26672 2.15326V3.43792C1.26672 3.57306 1.38014 3.68262 1.52006 3.68262H1.83672C1.97664 3.68262 2.09006 3.57306 2.09006 3.43792V2.15326C2.09006 2.01812 1.97664 1.90856 1.83672 1.90856H1.52006C1.38014 1.90856 1.26672 2.01812 1.26672 2.15326Z"
        fill="#C4C4C4"
      />
      <path
        d="M4.43372 2.21668L2.23399 14.1663C2.20949 14.2994 2.30131 14.4264 2.43906 14.4501L2.75083 14.5036C2.88859 14.5273 3.02011 14.4386 3.0446 14.3055L5.24434 2.35591C5.26883 2.22285 5.17702 2.09581 5.03926 2.07215L4.72749 2.0186C4.58974 1.99494 4.45821 2.08363 4.43372 2.21668Z"
        fill="#C4C4C4"
      />
      <path
        d="M10.4179 14.153L8.24234 2.33174C8.21785 2.19868 8.08633 2.10999 7.94858 2.13365L7.6368 2.18718C7.49905 2.21084 7.40723 2.33787 7.43171 2.47093L9.6073 14.2922C9.63179 14.4252 9.76331 14.5139 9.90106 14.4903L10.2128 14.4367C10.3506 14.4131 10.4424 14.286 10.4179 14.153Z"
        fill="#C4C4C4"
      />
      <path
        d="M3.10333 4.2334H0.253333C0.113421 4.2334 0 4.34295 0 4.4781V4.78397C0 4.91911 0.113421 5.02866 0.253333 5.02866H3.10333C3.24324 5.02866 3.35667 4.91911 3.35667 4.78397V4.4781C3.35667 4.34295 3.24324 4.2334 3.10333 4.2334Z"
        fill="#C4C4C4"
      />
      <path
        d="M5.60529 9C5.47327 9 5.37231 8.93836 5.30242 8.81507C5.23253 8.69178 5.23253 8.56849 5.30242 8.44521L9.82213 0.184932C9.89202 0.0616438 9.99298 0 10.125 0C10.257 0 10.358 0.0616438 10.4279 0.184932L14.9476 8.44521C15.0175 8.56849 15.0175 8.69178 14.9476 8.81507C14.8777 8.93836 14.7767 9 14.6447 9H5.60529ZM10.1716 3.4274C10.0706 3.4274 9.98716 3.46233 9.92115 3.53219C9.85514 3.60205 9.82213 3.69041 9.82213 3.79726V5.81918C9.82213 5.92603 9.85514 6.01438 9.92115 6.08425C9.98716 6.15411 10.0706 6.18904 10.1716 6.18904C10.2726 6.18904 10.356 6.15411 10.422 6.08425C10.4881 6.01438 10.5211 5.92603 10.5211 5.81918V3.79726C10.5211 3.69041 10.4881 3.60205 10.422 3.53219C10.356 3.46233 10.2726 3.4274 10.1716 3.4274ZM10.1716 7.55753C10.2726 7.55753 10.356 7.5226 10.422 7.45274C10.4881 7.38288 10.5211 7.29452 10.5211 7.18767C10.5211 7.08082 10.4881 6.99247 10.422 6.9226C10.356 6.85274 10.2726 6.81781 10.1716 6.81781C10.0706 6.81781 9.98716 6.85274 9.92115 6.9226C9.85514 6.99247 9.82213 7.08082 9.82213 7.18767C9.82213 7.29452 9.85514 7.38288 9.92115 7.45274C9.98716 7.5226 10.0706 7.55753 10.1716 7.55753Z"
        fill="#F4B54A"
      />
    </g>
  </svg>
</template>
