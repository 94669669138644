<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Notify } from 'quasar';
import LogoutButton from 'components/authentication/LogoutButton.vue';
import LCard from 'components/elements/Card';
import LBtn from 'components/elements/LBtn.vue';
import LItem from 'components/elements/LItem.vue';
import HealthCheckStatuses from 'components/HealthCheckStatuses';
import ManageAppState from 'components/ManageAppState.vue';
import LocalServiceInfo from 'components/LocalServiceInfo/LocalServiceInfo.vue';

import { useEnvironments } from 'composables/api/useEnvironments';
import { useAuth } from 'composables/useAuth';
import { useInstallationsManager } from 'composables/useInstallationsManager';
import { useSystemsStore } from 'stores/systems';
import { useAnalyticsStore } from 'stores/useAnalyticsStore';
import {
  buttonElement,
  inputElement,
  itemElement,
} from '@luminsmart/building-blocks';
import {
  RouteLocationAsRelativeGeneric,
  RouteRecordNameGeneric,
  RouteRecordRaw,
  useRouter,
} from 'vue-router';
import { useNetwork } from 'src/composables/useNetwork';
import { useSocketStore } from 'src/stores/useSocketStore';
const network = useNetwork();
const { t } = useI18n();
const systemsStore = useSystemsStore();
const analyticsStore = useAnalyticsStore();
const { isAuthenticated } = useAuth();
const { environments, getEnvironment, setEnvironment } = useEnvironments();
const router = useRouter();
const socketStore = useSocketStore();

const installationsManager = useInstallationsManager();
const activeInstallation = computed(
  () => installationsManager.getActiveInstallation.value,
);

const isFetchingLocalSystems = ref<boolean>(false);

async function fetchLocalSystems() {
  isFetchingLocalSystems.value = true;
  await systemsStore.fetchLocalSystems();
  isFetchingLocalSystems.value = false;
}

async function getBugReport() {
  const metadata = await analyticsStore.fetchMetadata();
  analyticsStore.trackEvent('bug-report', metadata);
  Notify.create({
    group: false,
    message: 'Bug report sent',
    position: 'top',
  });
}

function changeEnvironment(env: any) {
  setEnvironment(env);
  systemsStore.systems = [];
  systemsStore.setSelectedSystemId(null);
}

const systemId = ref('');

function routeTo(route: RouteRecordRaw) {
  const routeToGo: {
    name: RouteRecordNameGeneric | null;
    params?: { system_id: string };
  } = {
    name: route.name,
  };
  if (route.path.includes('system_id'))
    routeToGo.params = { system_id: systemId.value };
  router.push(routeToGo as RouteLocationAsRelativeGeneric);
}

const ssid = ref('');
const password = ref('');
function connectToWifi() {
  if (ssid.value.length === 0) {
    alert('you need to enter a ssid');
    return;
  }
  network.connectToWiFi(ssid.value, password.value);
}
function connectToSockets() {
  socketStore.connectToSocket(systemsStore.getSelectedSystemId);
}
</script>

<template>
  <q-page>
    <LCard data-testid="developer-settings">
      <template v-slot:label>{{ t('developer-settings') }}</template>
      <template v-slot:body>
        <div class="q-gutter-y-sm">
          <div class="text-subtitle">{{ t('environments') }}</div>
          <div v-if="isAuthenticated" class="text-red">
            You can only change environments while logged out.
          </div>
          <LItem
            v-for="environment in environments"
            class="l-pt-sm"
            :clickable="false"
            :key="environment.id"
            :label="environment.id"
          >
            <template v-slot:side>
              <LBtn
                @click="changeEnvironment(environment)"
                :unelevated="true"
                :class="{
                  'l-btn-secondary': getEnvironment.id !== environment.id,
                }"
                type="button"
                :flat="getEnvironment.id === environment.id"
                :disable="
                  getEnvironment.id === environment.id || isAuthenticated
                "
              >
                {{
                  getEnvironment.id === environment.id ? 'Selected' : ' Select'
                }}
              </LBtn>
            </template>
          </LItem>
          <div class="text-subtitle">Environment Config</div>
          <div class="text-small overflow-auto">
            <pre>{{ JSON.stringify(getEnvironment, null, 1) }}</pre>
          </div>
          <HealthCheckStatuses />

          <div class="l-pb-sm text-subtitle">ZeroConf Services</div>
          <LocalServiceInfo />
        </div>
      </template>
      <template v-slot:footer>
        <div class="q-gutter-y-sm">
          <itemElement label="Active Installation">
            <template #caption>{{ activeInstallation }}</template>
          </itemElement>
          <div class="l-pb-sm text-subtitle">Other Settings</div>
          <inputElement v-model="ssid" label="ssid" />
          <inputElement v-model="password" label="password" />

          <buttonElement
            @click="connectToWifi()"
            :unelevated="true"
            class="l-btn-secondary full-width"
            label="Connect to wifi"
          />
          <itemElement label="Route To Page">
            <template #bottomSheet>
              <itemElement
                v-for="layout in router.options.routes[0].children"
                :key="layout.name"
                :label="layout.name"
              >
                <template #bottomSheet>
                  <inputElement v-model="systemId" />
                  <itemElement
                    v-for="route in layout.children"
                    :key="route.name"
                    :label="route.name"
                    :caption="route"
                    @click="() => routeTo(route)"
                  />
                </template>
              </itemElement>
            </template>
          </itemElement>

          <LBtn
            :unelevated="true"
            class="l-btn-secondary full-width"
            type="button"
            @click="getBugReport()"
          >
            <div>Send bug report</div>
          </LBtn>

          <LBtn
            :unelevated="true"
            class="l-btn-secondary full-width"
            type="button"
            @click="fetchLocalSystems()"
            :loading="isFetchingLocalSystems"
            :disabled="isFetchingLocalSystems"
          >
            <div>Fetch local systems</div>
          </LBtn>

          <LBtn
            :unelevated="true"
            class="l-btn-secondary full-width"
            type="button"
            @click="connectToSockets()"
          >
            <div>Connect to sockets</div>
          </LBtn>
          <ManageAppState />
          <LogoutButton />
        </div>
      </template>
    </LCard>
  </q-page>
</template>
