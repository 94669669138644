import * as LiveUpdates from '@capacitor/live-updates';
import { App } from '@capacitor/app';

export function useLiveUpdates() {
  App.addListener('resume', async () => {
    if (localStorage.shouldReloadApp === 'true') {
      await LiveUpdates.reload();
    } else {
      await sync();
    }
  });

  async function sync() {
    const result = await LiveUpdates.sync();
    localStorage.shouldReloadApp = result.activeApplicationPathChanged;
  }

  // Dynamically set the channel and max versions
  async function setConfig(config: LiveUpdates.LiveUpdateConfig) {
    await LiveUpdates.setConfig(config);
  }

  async function getConfig() {
    return await LiveUpdates.getConfig();
  }

  async function resetConfig() {
    await LiveUpdates.resetConfig();
  }

  return { sync, setConfig, getConfig, resetConfig };
}
