import axios from 'axios';
import { useEnvironments } from 'src/composables/api/useEnvironments.ts';
const { getEnvironment } = useEnvironments();

export const api = axios.create({
  baseURL: getEnvironment.value.api.VITE_API_BASE_URL,
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
