<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15ZM10.6649 6.6565C10.7257 6.7411 10.7307 6.8383 10.6781 6.9268L7.24248 12.7009C7.1843 12.7987 7.0677 12.8571 6.94458 12.8571C6.9128 12.8571 6.8806 12.8549 6.84881 12.8468C6.69404 12.8072 6.5946 12.6918 6.61256 12.5546L7.12642 8.64425H4.61982C4.5041 8.64425 4.39668 8.57578 4.33575 8.49172C4.27486 8.40761 4.26918 8.28978 4.32071 8.20129L7.75587 2.30142C7.8133 2.20286 7.9299 2.14286 8.05327 2.14286C8.08539 2.14286 8.11792 2.14675 8.15003 2.15489C8.30564 2.19437 8.40554 2.33708 8.38692 2.47484L7.84011 6.53813H10.3802C10.4964 6.53813 10.6042 6.57186 10.6649 6.6565Z"
      fill="#1DC59C"
      fill-opacity="0.8"
    />
  </svg>
</template>
