// This file is used to generate all of our custom icons and make the globally available.
// The main reason for doing it this way is to to actually render the svg as a vue component so we
// can interact with it for state change
import { App, Component } from 'vue';
interface ComponentObject {
  default: Component;
}
const modules: Record<string, ComponentObject> = import.meta.glob(
  ['/src/components/elements/LIcon/IconSet/*.vue'],
  { eager: true },
);

export function loadComponents(app: App) {
  for (const path in modules) {
    const componentName = path.split('/')?.at(-1)?.split('.').at(0);
    // the folder that the component live in will give us the theme name (IE dark or light or company_theme)

    app.component(`${componentName}`, modules[path].default);
  }
}
