<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 15.5C11.6421 15.5 15 12.1421 15 8C15 3.85786 11.6421 0.5 7.5 0.5C3.35786 0.5 0 3.85786 0 8C0 12.1421 3.35786 15.5 7.5 15.5ZM7.8499 4.50002C7.8499 4.03058 7.46934 3.65002 6.9999 3.65002C6.53046 3.65002 6.1499 4.03058 6.1499 4.50002V8.41975C6.1499 8.7541 6.317 9.06633 6.5952 9.2518L9.52841 11.2073C9.91901 11.4677 10.4467 11.3621 10.7071 10.9715C10.9675 10.5809 10.862 10.0532 10.4714 9.79278L7.8499 8.04512V4.50002Z"
      fill="#565B63"
    />
  </svg>
</template>
