<script setup lang="ts">
import localforage from 'localforage';
import { ref } from 'vue';
import { Notify } from 'quasar';
import { $ResetPinia, $GetAllStates } from 'src/stores/util/ResetPinia';
import LBtn from 'components/elements/LBtn.vue';

const piniaState = ref();
function getState() {
  piniaState.value = $GetAllStates();
}
function resetState() {
  $ResetPinia();
}

function clearLocalForge() {
  localforage
    .clear()
    .then(function () {
      // Run this code once the database has been entirely deleted.
      Notify.create({
        group: false,
        message: 'Cache cleared',
      });
    })
    .catch(function (err) {
      // This code runs if there were any errors
      console.error(err);
      Notify.create({
        group: false,
        message: 'There was an error ' + err,
      });
    });
}

function clearLocalStorage() {
  localStorage.clear();
}
function isObject(data: any) {
  return typeof data === 'object';
}
</script>
<template>
  <LBtn
    :unelevated="true"
    class="l-btn-secondary full-width"
    type="button"
    @click="getState"
  >
    Print Store
  </LBtn>

  <div v-if="piniaState" class="text-small overflow-auto">
    <div v-for="(state, index) in piniaState" :key="index">
      <div class="text-label">{{ index }}</div>

      <div v-for="(info, key) in state" :key>
        <div class="text-caption">{{ key }}</div>
        <div v-if="!isObject(info)">
          {{ info }}
        </div>
        <div v-else v-for="(data, i) in info" :key="i">
          {{ data }}
        </div>
      </div>
    </div>
  </div>

  <LBtn
    :unelevated="true"
    class="l-btn-secondary full-width"
    type="button"
    @click="resetState"
  >
    Reset Store
  </LBtn>
  <LBtn
    :unelevated="true"
    class="l-btn-secondary full-width"
    type="button"
    @click="clearLocalForge()"
  >
    Clear IndexDB
  </LBtn>

  <LBtn
    :unelevated="true"
    class="l-btn-secondary full-width"
    type="button"
    @click="clearLocalStorage()"
  >
    Clear Local Storage
  </LBtn>
</template>
